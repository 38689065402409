import React from "react";
import { indexToAlphabet } from "../../../../Helpers/utils";

const TrueFalse = ({ question, index }) => {
  return (
    <div className="flex flex-col items-start text-center justify-start">
      <div className="text-[#90919A] text-lg">Question {index}</div>
      <div className="text-[#1F204A] text-2xl font-normal pt-10 text-start">
        {question.data.title}
        {question.data?.toggleMenus?.filter(
          (menu) => menu.title === "Required"
        )?.[0]?.value === true && <span className="text-red-500 pl-1">*</span>}
      </div>

      <div className="py-2 flex flex-col gap-3 pt-8">
        <div className="flex gap-2 border-[1px] rounded border-[#BEC6D3] p-2 bg-[#1F204A]/5 w-[200px] truncate  items-center justify-start">
          <div className="text-xs font-normal px-2 py-1 rounded-sm border-[1px] border-[#BEC6D3] bg-white">
            A
          </div>
          <div className="pr-4 text-sm text-start bg-transparent ring-0 focus:outline-none focus:ring-0">
            True
          </div>
        </div>
        <div className="flex gap-2 border-[1px] rounded border-[#BEC6D3] p-2 bg-[#1F204A]/5 w-[200px] truncate  items-center justify-start">
          <div className="text-xs font-normal px-2 py-1 rounded-sm border-[1px] border-[#BEC6D3] bg-white">
            B
          </div>
          <div className="pr-4 text-sm text-start bg-transparent ring-0 focus:outline-none focus:ring-0">
            False
          </div>
        </div>
      </div>

      <div className="w-full flex items-start gap-1 pt-14">
        <button className="flex items-center justify-center text-base gap-2 px-6 py-2 rounded-md bg-[#2972C6] text-white text-center">
          Submit
        </button>
        {question.data?.toggleMenus?.filter(
          (menu) => menu.title === "Required"
        )?.[0]?.value !== true && (
          <button className="flex items-center justify-center text-center text-base gap-2 px-4 py-2 ">
            Skip
          </button>
        )}
      </div>
    </div>
  );
};

export default TrueFalse;
