import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const PaymentVarification = () => {
  const [paymentStatus, setPaymentStatus] = useState("fetching...");
  const fetchSubscriptionStatus = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
      let res = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/users/subscription-status/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPaymentStatus(JSON.stringify(res.data));
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);

  return <div>{paymentStatus}</div>;
};

export default PaymentVarification;
