import React, { useEffect, useState } from "react";
import LeadsTable from "../../LeadsTable";
import { getLeadsApi } from "../../../../../APIs/analytics";
import { useParams } from "react-router-dom";
import { BiLoader } from "react-icons/bi";

const RecentLeads = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [columns, setColoums] = useState(null);
  const [data, setData] = useState(null);
  const [leads, setLeads] = useState(null);

  const fetchAndSetLeads = async () => {
    setLoading(true);
    const res = await getLeadsApi(params.id);
    setLeads(res.data);
    createDataAndColums(res.data.results);
    setLoading(false);
  };

  const createDataAndColums = (leads) => {
    let newColumHeaders = [];
    let newColums = [
      {
        Header: "ID",
        accessor: "session_id",
      },
    ];
    leads.forEach((lead) => {
      Object.keys(lead.details).forEach((header) => {
        if (newColumHeaders.includes(header)) return;
        newColumHeaders.push(header);
        newColums.push({
          Header: header,
          accessor: header,
        });
      });
    });
    setColoums(newColums);

    let newData = [];

    leads.forEach((lead) => {
      let leadData = {};
      newColums.forEach((column) => {
        if (column.accessor === "session_id") {
          leadData[column.accessor] = lead["session_id"];
          return;
        }
        if (lead?.details?.[column?.accessor]) {
          leadData[column.accessor] = lead?.details?.[column?.accessor];
          return;
        }
        leadData[column.accessor] = "Skipped";
      });
      newData.push(leadData);
    });
    setData(newData);
  };
  useEffect(() => {
    fetchAndSetLeads();
  }, []);

  const fetchNextLeads = async () => {
    setLoading(true);
    const res = await getLeadsApi(params.id, leads.next);
    setLeads(res.data);
    createDataAndColums(res.data.results);
    setLoading(false);
  };
  const fetchPrevLeads = async () => {
    setLoading(true);
    const res = await getLeadsApi(params.id, leads.previous);
    setLeads(res.data);
    createDataAndColums(res.data.results);
    setLoading(false);
  };

  return (
    <div>
      <div className="flex flex-col w-full">
        <div className="text-[#111927] text-xl font-medium py-7">
          Recently collected leads
        </div>
        {columns && data && (
          <div className="w-full gap-4">
            {loading ? (
              <div className="w-full h-full items-center justify-center text-center font-medium">
                <div className="flex items-center justify-center gap-2">
                  <div className="animate-spin w-fit h-fit text-xl">
                    <BiLoader />
                  </div>
                </div>
              </div>
            ) : (
              <LeadsTable columns={columns} data={data} />
            )}

            <div className="flex w-full p-4 items-center justify-end gap-4">
              <button
                disabled={!leads.previous}
                onClick={fetchPrevLeads}
                className="bg-gray-100 px-3 py-1 rounded-md disabled:opacity-50"
              >
                Previous
              </button>
              <button
                disabled={!leads.next}
                onClick={fetchNextLeads}
                className="bg-gray-100 px-3 py-1 rounded-md disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentLeads;
