import { BiChevronDown } from "react-icons/bi";
import React, { useState } from "react";
import QuestionsMenu from "./QuestionsMenu";

const ToggleSelectedQue = ({ question }) => {
  const [showQuestionsMenu, setShowQuestionsMenu] = useState(false);
  const toggleQuestionButtonRef = React.createRef();
  return (
    <div className="flex relative">
      <button
        ref={toggleQuestionButtonRef}
        className="flex gap-2 items-center justify-center bg-white rounded-lg p-2 cursor-pointer border-[1px] border-[#E8EAEC]"
        onClick={() => {
          // setShowQuestionsMenu(!showQuestionsMenu);
        }}
      >
        <img className="h-6 w-6" src={question.value.icon} />
        <div className="text-sm font-medium min-w-[80px] text-start pr-2">
          {question.value.queName}
        </div>
        {/* <BiChevronDown /> */}
      </button>
      <div className="absolute top-[40px] left-0">
        {showQuestionsMenu && (
          <QuestionsMenu context={"ToggleSelectedQue"} data={question} />
        )}
      </div>
    </div>
  );
};

export default ToggleSelectedQue;
