import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getQuizSideBarDataApi,
  updateQuizSideBarDataApi,
} from "../../../../APIs/share";
import { toast } from "react-toastify";
import bgImage from "../../../../assets/images/side_note_bg.png";
import { BiCodeAlt, BiLoader } from "react-icons/bi";

const config = [
  {
    title: "Side Note Text",
    id: "text",
    value: "Text goes here",
    type: "input",
  },
  {
    title: "Text colour",
    id: "text_color",
    value: "#ffffff",
    type: "input",
  },
  {
    title: "Font size",
    id: "font_size",
    value: "20px",
    type: "input",
  },
  {
    title: "Background colour",
    id: "bg_color",
    value: "#00000",
    type: "input",
  },
  {
    title: "Side note radius",
    id: "radius",
    value: "2px",
    type: "input",
  },
  {
    title: "Select  Alignment",
    id: "alignment",
    value: "left",
    type: "selector",
    selectorItems: [
      {
        value: "left",
        label: "Left Alignment",
      },
      {
        value: "right",
        label: "Right Alignment",
      },
    ],
  },
];

const SettingsItem = ({ item, settings, handleChange }) => {
  return (
    <div className="flex flex-col gap-2 items-start justify-start w-full">
      <div className="text-sm font-medium text-[#1F204A]">{item.title}</div>
      {item.type === "input" && (
        <input
          type="text"
          value={settings[item.id]}
          className="p-3 rounded-md placeholder:text-[#90919A] border-[1px] border-[#EFF0F6] w-full"
          onChange={(e) => {
            handleChange(item, e.target.value);
          }}
        />
      )}
      {item.type === "selector" && (
        <div className="flex gap-2">
          {item.selectorItems.map((selctorItem) => {
            return (
              <div
                onClick={() => {
                  console.log(item.value);
                  handleChange(item, selctorItem.value);
                }}
                className="flex gap-2"
              >
                <label className="text-[#1F204A] font-medium gap-2">
                  <input
                    className="m-1"
                    name="content_type"
                    type="radio"
                    checked={settings[item.id] === selctorItem.value}
                  />
                  {selctorItem.label}
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const SideNote = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [showCode, setShowCode] = useState(false);
  const [quizShareId, setQuizShareId] = useState(null);
  const [settings, setSettings] = useState({
    text: "Text goes here",
    text_color: "#ffffff",
    font_size: "20px",
    bg_color: "#00000",
    radius: "2px",
    alignment: "left",
  });

  const genCode = () => {
    return ` <div>
    <link
      rel="stylesheet"
      type="text/css"
      href="https://d12zbtru1o375r.cloudfront.net/ancbar/ancbar.css"
    />
    <section style="background: ${
      settings.bar_bg_color
    }" class="barEmbed" id="barEmbed">
      <p style="color: ${settings.bar_text_color}; font-size: ${
      settings.bar_font_size
    }">${settings.bar_text}</p>
      <a
        style="
          border-radius: ${settings.btn_border_radius};
          color: ${settings.btn_text_color};
          font-size: ${settings.btn_font_size};
          background: ${settings.btn_bg_color};
        "
        href="javascript:void(0);"
        onclick="openGreetPopup()"
        class="greet-popup-open"
        >${settings.btn_text}</a
      >

      ${
        settings.btn_can_close
          ? ` <a
      href="javascript:void(0);"
      class="greetbar-popupClose"
      onClick="closeGreetBar()"
    ></a>`
          : ``
      }
     
    </section>
    <section class="greet_popup" id="greet_popup" style="display: none">
      <iframe
        title="leadsbuddha quiz"
        src="https://render.leadsbuddha.com/${params.id}"
      ></iframe>
      <a
        href="javascript:void(0);"
        class="close-popup"
        onClick="closeGreetPopup()"
      ></a>
    </section>
    <script src="https://d12zbtru1o375r.cloudfront.net/ancbar/ancbar.js"></script>
  </div>`;
  };

  const handleChange = (item, newValue) => {
    setShowCode(false);
    console.log(item);
    console.log(newValue);
    const newSettings = {
      ...settings,
    };
    newSettings[item.id] = newValue;
    setSettings((prev) => newSettings);
    setShowCode(false);
  };

  const fetchAndSaveSideBarData = async () => {
    try {
      setLoading(true);
      const res = await getQuizSideBarDataApi(params.id);
      console.log(res);
      setSettings({
        text: res?.data?.text,
        text_color: res?.data?.text_color,
        font_size: res?.data?.font_size,
        bg_color: res?.data?.bg_color,
        radius: res?.data?.radius,
        alignment: res?.data?.alignment,
      });
      setQuizShareId(res?.data?.quiz);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("failed to fetch data");
    }
  };

  useEffect(() => {
    fetchAndSaveSideBarData();
  }, []);

  return (
    <div className="flex flex-col w-full h-full overflow-y-scroll">
      {loading ? (
        <div className="w-full h-full items-center justify-center text-center py-48">
          <div className="flex items-center justify-center gap-2">
            <div className="animate-spin w-fit h-fit text-3xl">
              <BiLoader />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-5">
          <div className="flex w-full h-full ">
            <div className="w-[40%] flex flex-col gap-7">
              <div className="text-lg font-normal text-[#1F204A]">
                Configure Side Note
              </div>
              <div className="flex flex-col gap-5 w-full">
                <div className="flex flex-col gap-5">
                  {config.map((item) => {
                    return (
                      <SettingsItem
                        item={item}
                        settings={settings}
                        handleChange={handleChange}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="w-[60%] p-20">
              <div className="flex relative w-full h-fit">
                <img src={bgImage} alt="bg-image" className="w-full h-auto" />
                <section
                  style={{
                    right: settings.alignment === "right" ? "0px" : "auto",
                    left: settings.alignment === "left" ? "0px" : "auto",
                    background: settings.bar_bg_color,
                  }}
                  className="h-fit flex absolute top-1/2 transform -translate-y-1/2 "
                >
                  <a
                    style={{
                      transform:
                        settings.alignment === "right"
                          ? "rotate(-90deg) translateY(100%)"
                          : "rotate(-90deg) translateY(200%)", // Adjusted translation
                      borderRadius: settings.radius,
                      color: settings.text_color,
                      fontSize: settings.font_size,
                      background: settings.bg_color,
                    }}
                    className="py-1 px-4"
                  >
                    {settings.text}
                  </a>
                </section>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            {!showCode ? (
              <button
                onClick={async () => {
                  await updateQuizSideBarDataApi(params.id, {
                    ...settings,
                    quiz: quizShareId,
                  });
                  setShowCode(true);
                }}
                className="text-lg p-3 rounded-md font-normal text-white w-fit bg-[#171055]"
              >
                Save & Get Code
              </button>
            ) : (
              <div className="flex flex-col gap-5">
                <div className="bg-gray-100 p-4 rounded-xl">{genCode()}</div>
                <button
                  onClick={async () => {
                    navigator.clipboard.writeText(genCode());
                    toast.success("code copied");
                  }}
                  className="text-lg p-3 rounded-md font-normal flex gap-1 text-center items-center justify-center text-white w-fit bg-[#171055]"
                >
                  <BiCodeAlt /> Copy code
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SideNote;
