import { MdDelete } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import React, { useState } from "react";
import ToggleSelectedQue from "../ToggleSelectedQue";
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  changeQuestionTitle,
  replaceQuizQuestion,
  toggleQuestionMenuItem,
  updateSelectedQuestion,
} from "../../../../../State/Redux/features/quizzes/quizzesSlice";
import { updateQuestion } from "../../../../../Helpers/utils";

const TrueFalse = ({ question }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [showSave, setShowSave] = useState(true);

  const handleTitleQuestionUpdate = (e) => {
    dispatch(
      changeQuestionTitle({
        quizId: params.id,
        questionIndex: question.index,
        newTitle: e.target.value,
      })
    );
    setShowSave(true);
  };

  const toggleMenuItem = (toggleMenu) => {
    dispatch(
      toggleQuestionMenuItem({
        quizId: params.id,
        questionIndex: question.index,
        toggleMenu,
      })
    );
    setShowSave(true);
  };
  const handleSaveToDraft = async () => {
    const res = await updateQuestion(question.value, params.id);
    if (res) {
      setShowSave(false);
      dispatch(
        updateSelectedQuestion({
          quizId: params.id,
          questionIndex: null,
        })
      );
    }
  };
  return (
    <div className="w-full h-fit flex flex-col border-[1px] rounded-lg ">
      <div className="bg-[#F6F7FA] rounded-t-lg px-5 py-3 flex w-full items-center justify-between">
        <ToggleSelectedQue question={question} />

        <div className="flex gap-2 w-fit h-full">
          {question.value.data.toggleMenus.map((toggleMenu) => {
            return (
              <div className="flex gap-2 items-center justify-center">
                <div className="font-medium text-[#1F204A] text-sm">
                  {toggleMenu.title}
                </div>

                <Switch
                  height={15}
                  width={27}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  offColor={"#BEC6D3"}
                  onColor={"#4A3AFF"}
                  onHandleColor={"#ffffff"}
                  offHandleColor={"#ffffff"}
                  checked={toggleMenu.value}
                  onChange={() => toggleMenuItem(toggleMenu)}
                />
              </div>
            );
          })}
          {showSave ? (
            <button
              onClick={handleSaveToDraft}
              className="px-3 py-[6px] h-fit rounded bg-[#171055] text-white font-medium text-sm"
            >
              Save
            </button>
          ) : (
            <div
              onClick={handleSaveToDraft}
              className="h-full items-center flex justify-center opacity-50"
            >
              Saved
            </div>
          )}
        </div>
      </div>
      <div className="px-5 pt-2 text-[#90919A]">Question</div>

      <div className="px-5 py-2">
        <textarea
          className="w-full min-h-[40px] items-start justify-start flex text-start ring-0 focus:ring-0 focus:outline-none"
          type="text"
          value={question.value.data.title}
          onChange={(e) => {
            handleTitleQuestionUpdate(e);
          }}
          placeholder="Type your question here"
        />
      </div>
      <div className="px-5 py-2 pb-4 flex flex-col gap-3 w-fit">
        <div className="flex gap-2 border-[1px] rounded border-[#BEC6D3] p-2 bg-[#1F204A]/5 w-full truncate  items-center justify-start">
          <div className="text-xs font-normal px-2 py-1 rounded-sm border-[1px] border-[#BEC6D3] bg-white">
            A
          </div>
          <div className="pr-4 text-sm text-start">True</div>
        </div>
        <div className="flex gap-2 border-[1px] rounded border-[#BEC6D3] p-2 bg-[#1F204A]/5 w-full truncate  items-center justify-start">
          <div className="text-xs font-normal px-2 py-1 rounded-sm border-[1px] border-[#BEC6D3] bg-white">
            B
          </div>
          <div className="pr-4 text-sm text-start">False</div>
        </div>
      </div>
    </div>
  );
};

export default TrueFalse;
