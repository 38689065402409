import React, { useState } from "react";
import ToggleSelectedQue from "../ToggleSelectedQue";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  changeQuestionTitle,
  updateSelectedQuestion,
} from "../../../../../State/Redux/features/quizzes/quizzesSlice";
import { updateQuestion } from "../../../../../Helpers/utils";

const EndScreen = ({ question }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [showSave, setShowSave] = useState(true);
  const handleTitleQuestionUpdate = (e) => {
    dispatch(
      changeQuestionTitle({
        quizId: params.id,
        questionIndex: question.index,
        newTitle: e.target.value,
      })
    );
    setShowSave(true);
  };

  const handleSaveToDraft = async () => {
    const res = await updateQuestion(question.value, params.id);
    if (res) {
      setShowSave(false);
      dispatch(
        updateSelectedQuestion({
          quizId: params.id,
          questionIndex: null,
        })
      );
    }
  };
  return (
    <div className="w-full h-fit flex flex-col border-[1px] rounded-lg ">
      <div className="bg-[#F6F7FA] rounded-t-lg px-5 py-3 flex w-full  justify-between items-center">
        <ToggleSelectedQue question={question} />
        {showSave ? (
          <button
            onClick={handleSaveToDraft}
            className="px-3 py-[6px] h-fit rounded bg-[#171055] text-white font-medium text-sm"
          >
            Save
          </button>
        ) : (
          <div
            onClick={handleSaveToDraft}
            className="h-full items-center flex justify-center opacity-50"
          >
            Saved
          </div>
        )}
      </div>

      <div className="p-5">
        <textarea
          className="w-full min-h-[100px] items-start justify-start flex text-start ring-0 focus:ring-0 focus:outline-none"
          type="text"
          value={question.value.data.title}
          onChange={(e) => {
            handleTitleQuestionUpdate(e);
          }}
          placeholder="Bye! Thank you for taking the survey. We appreciate your input!"
        />
      </div>
    </div>
  );
};

export default EndScreen;
