import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import RenderQueListItem from "./RenderQueListItem";
import RenderSelectedQue from "./RenderSelectedQue";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { questionDragDrop } from "../../../../State/Redux/features/quizzes/quizzesSlice";

const QuestionsList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const quizzes = useSelector((state) => state.quizzes).list;

  const [quiz, setQuiz] = useState({
    data: quizzes.filter((quiz) => quiz.id === params.id)[0],
  });

  useEffect(() => {
    setQuiz({
      data: quizzes.filter((quiz) => quiz.id === params.id)[0],
    });
  }, [quizzes, params.id]);

  const handleDragDrop = (results) => {
    console.log(results);
    console.log(quiz);
    const sourceQueType =
      quiz.data.questions[results.destination.index].queType;

    let destinationQuestionIndex = results.destination.index;
    if (sourceQueType === "welcome_screen")
      destinationQuestionIndex = results.destination.index + 1;
    if (sourceQueType === "end_screen")
      destinationQuestionIndex = results.destination.index - 1;

    dispatch(
      questionDragDrop({
        quizId: params.id,
        sourceQuestionIndex: results.source.index,
        destinationQuestionIndex,
      })
    );
  };
  return (
    <DragDropContext onDragEnd={handleDragDrop}>
      <Droppable droppableId="questions" type="group">
        {(provided, snapshot) => (
          <div
            className="w-full flex flex-col gap-3"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {quiz.data.questions.map((question, index) => {
              return index === quiz.data.selectedQuestionIndex ? (
                <Draggable
                  key={question?.id?.toString()}
                  draggableId={question?.id?.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div {...provided.draggableProps} ref={provided.innerRef}>
                      <div
                        {...provided.dragHandleProps}
                        className="hidden"
                      ></div>
                      <RenderSelectedQue
                        question={{
                          value: question,
                          index,
                        }}
                      />
                    </div>
                  )}
                </Draggable>
              ) : (
                <Draggable
                  key={question?.id?.toString()}
                  draggableId={question?.id?.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div {...provided.draggableProps} ref={provided.innerRef}>
                      <RenderQueListItem
                        provided={provided}
                        question={{
                          value: question,
                          index,
                        }}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default QuestionsList;
