const defaultUserState = {
  isLoggedIn: false,
};

const defaultSettingsState = {
  darkMode: false,
  fontSize: "medium",
  fontFamily: "Roboto",
  theme: "default",
  language: "en",
  previewType: "desktop",
};

const defaultQuizzesState = {
  list: [],
};

export const getPlatformUserState = () => {
  const buildType = process.env.REACT_APP_BUILD;
  console.log("getPlatformUserState");

  if (buildType === "web") {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) return user;
    else return defaultUserState;
  } else {
    console.log("WARNING: process.env.REACT_APP_BUILD is not set");
    return {};
  }
};
export const setPlatformUserState = (state) => {
  const buildType = process.env.REACT_APP_BUILD;
  if (buildType === "web") {
    localStorage.setItem("user", JSON.stringify(state));
  } else {
    console.log("WARNING: process.env.REACT_APP_BUILD is not set");
    return {};
  }
};

export const getPlatformSettingsState = () => {
  console.log("getPlatformSettingsState");
  const buildType = process.env.REACT_APP_BUILD;
  if (buildType === "web") {
    const settings = JSON.parse(localStorage.getItem("settings"));
    if (settings) return settings;
    else return defaultSettingsState;
  } else {
    console.log("WARNING: process.env.REACT_APP_BUILD is not set");
    return {};
  }
};

export const setPlatformSettingsState = (state) => {
  const buildType = process.env.REACT_APP_BUILD;
  if (buildType === "web") {
    localStorage.setItem("settings", JSON.stringify(state));
  } else {
    console.log("WARNING: process.env.REACT_APP_BUILD is not set");
    return {};
  }
};

export const getPlatformQuizzesState = () => {
  console.log("getPlatformQuizzesState");
  const buildType = process.env.REACT_APP_BUILD;
  if (buildType === "web") {
    const quizzes = JSON.parse(localStorage.getItem("quizzes"));
    if (quizzes) return quizzes;
    else return defaultQuizzesState;
  } else {
    console.log("WARNING: process.env.REACT_APP_BUILD is not set");
    return {};
  }
};

export const setPlatformQuizzesState = (state) => {
  const buildType = process.env.REACT_APP_BUILD;
  if (buildType === "web") {
    localStorage.setItem("quizzes", JSON.stringify(state));
  } else {
    console.log("WARNING: process.env.REACT_APP_BUILD is not set");
    return {};
  }
};
