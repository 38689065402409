import React, { useEffect, useState } from "react";
import LeadsTable from "../LeadsTable";
import { useParams } from "react-router-dom";
import { getLeadsApi } from "../../../../APIs/analytics";
import { BiLoader, BiSearch } from "react-icons/bi";
import moment from "moment";
import ReactDatePicker from "react-datepicker";

let searchDebounceTimer;

const Leads = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [columns, setColoums] = useState(null);
  const [data, setData] = useState(null);
  const [Leads, setLeads] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState("");

  const fetchAndSetLeads = async () => {
    setLoading(true);
    const res = await getLeadsApi(params.id);
    setLeads(res.data);
    createDataAndColums(res.data.results);
    setLoading(false);
  };
  const createDataAndColums = (leads) => {
    let newColumHeaders = [];
    let newColums = [
      {
        Header: "ID",
        accessor: "session_id",
      },
    ];
    leads.forEach((lead) => {
      Object.keys(lead.details).forEach((header) => {
        if (newColumHeaders.includes(header)) return;
        newColumHeaders.push(header);
        newColums.push({
          Header: header,
          accessor: header,
        });
      });
    });
    setColoums(newColums);

    let newData = [];

    leads.forEach((lead) => {
      let leadData = {};
      newColums.forEach((column) => {
        if (column.accessor === "session_id") {
          leadData[column.accessor] = lead["session_id"];
          return;
        }
        if (lead?.details?.[column?.accessor]) {
          leadData[column.accessor] = lead?.details?.[column?.accessor];
          return;
        }
        leadData[column.accessor] = "Skipped";
      });
      newData.push(leadData);
    });
    setData(newData);
  };
  useEffect(() => {
    fetchAndSetLeads();
  }, []);

  const fetchNextResults = async () => {
    setLoading(true);
    const res = await getLeadsApi(
      params.id,
      Leads?.next,
      searchText,
      startDate,
      endDate
    );
    setLeads(res.data);
    createDataAndColums(res.data.results);
    setLoading(false);
  };
  const fetchPrevResults = async () => {
    setLoading(true);
    const res = await getLeadsApi(
      params.id,
      Leads?.previous,
      searchText,
      startDate,
      endDate
    );
    setLeads(res.data);
    createDataAndColums(res.data.results);
    setLoading(false);
  };

  return (
    <div>
      {Leads?.length < 1 ? (
        <div className="flex  w-full items-center justify-center p-24">
          No Leads Recieved{" "}
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between w-full">
            <div className="text-[#1F204A] font-semibold text-2xl">
              All Leads
            </div>
            <button className="bg-[#4A3AFF] rounded-xl px-5 py-3 text-white text-sm">
              Download Leads
            </button>
          </div>
          <div className="flex w-full items-center gap-4 pt-4">
            <div className="relative w-full">
              <div className="absolute top-1/2 -translate-y-1/2 left-3">
                <BiSearch />
              </div>
              <input
                className="w-full pl-8 flex items-center justify-center border border-[#1F204A]/10 text-[#1F204A] py-1 font-medium rounded-lg"
                type="text"
                value={searchText}
                onChange={async (e) => {
                  setSearchText(e.target.value);

                  clearTimeout(searchDebounceTimer);

                  searchDebounceTimer = setTimeout(async () => {
                    setLoading(true);
                    const startDateYYYYMMDD = startDate
                      ? moment(startDate).format("YYYY-MM-DD")
                      : null;
                    const endDateYYYYMMDD = endDate
                      ? moment(endDate).format("YYYY-MM-DD")
                      : null;
                    const res = await getLeadsApi(
                      params.id,
                      1,
                      e.target.value,
                      startDateYYYYMMDD,
                      endDateYYYYMMDD
                    );
                    setLeads(res.data);
                    createDataAndColums(res.data.results);
                    setLoading(false);
                  }, 1000);
                }}
                placeholder="Search Responses"
              ></input>
            </div>

            <div className="flex w-fit items-center justify-center gap-3">
              <ReactDatePicker
                placeholderText="Start Date"
                selected={startDate}
                onChange={async (date) => {
                  setLoading(true);
                  setStartDate(date);
                  const startDateYYYYMMDD = moment(date).format("YYYY-MM-DD");
                  const endDateYYYYMMDD = moment(endDate).format("YYYY-MM-DD");
                  const res = await getLeadsApi(
                    params.id,
                    1,
                    null,
                    startDateYYYYMMDD,
                    endDateYYYYMMDD
                  );
                  setLeads(res.data);
                  createDataAndColums(res.data.results);
                  setLoading(false);
                }}
                selectsStart
                startDate={startDate}
                maxDate={new Date()}
                endDate={endDate}
                className="bg-white border-[1px] px-2 py-1 rounded-lg border-[#1F204A]/10 text-[#1F204A]"
              />
              <ReactDatePicker
                placeholderText="End Date"
                selected={endDate}
                onChange={async (date) => {
                  setLoading(true);
                  setEndDate(date);

                  const startDateYYYYMMDD =
                    moment(startDate).format("YYYY-MM-DD");
                  const endDateYYYYMMDD = moment(date).format("YYYY-MM-DD");
                  const res = await getLeadsApi(
                    params.id,
                    1,
                    null,
                    startDateYYYYMMDD,
                    endDateYYYYMMDD
                  );
                  setLeads(res.data);
                  createDataAndColums(res.data.results);
                  setLoading(false);
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={new Date()}
                className="bg-white border-[1px] px-2 py-1 rounded-lg border-[#1F204A]/10 text-[#1F204A]"
              />
            </div>
          </div>
          {columns && data && (
            <div className="w-full gap-4">
              {loading ? (
                <div className="w-full h-full items-center justify-center text-center font-medium">
                  <div className="flex items-center justify-center gap-2">
                    <div className="animate-spin w-fit h-fit text-xl">
                      <BiLoader />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full pt-5  overflow-scroll">
                  <LeadsTable columns={columns} data={data} />
                </div>
              )}

              <div className="flex w-full p-4 items-center justify-end gap-4">
                <button
                  disabled={!Leads.previous}
                  onClick={fetchPrevResults}
                  className="bg-gray-100 px-3 py-1 rounded-md disabled:opacity-50"
                >
                  Previous
                </button>
                <button
                  disabled={!Leads.next}
                  onClick={fetchNextResults}
                  className="bg-gray-100 px-3 py-1 rounded-md disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Leads;
