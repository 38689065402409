import React from "react";
import formsLanding from "../../../assets/images/formsLanding.png";

const FirstFormLanding = ({ openPopup }) => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center pt-28">
      <div className="flex flex-col items-center justify-center text-center">
        <img src={formsLanding} alt="formsLanding" className="w-fit h-48" />
        <div className="pt-14 text-5xl font-bold">
          Get Started with Leads Buddha
        </div>
        <div className="pt-6 text-lg text-[#90919A]">
          Click create new, to create your first lead generation quiz
        </div>
        <button
          onClick={openPopup}
          className="mt-14 px-16 py-4 rounded-lg bg-[#4A3AFF] text-white"
        >
          Create New
        </button>
      </div>
    </div>
  );
};

export default FirstFormLanding;
