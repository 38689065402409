import { BsLink45Deg } from "react-icons/bs";
import { BiDotsVerticalRounded } from "react-icons/bi";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteQuiz } from "../../../State/Redux/features/quizzes/quizzesSlice";
import { deleteQuizApi } from "../../../APIs/quiz";
import { useOnClickOutsideRef } from "../../../Helpers/hooks";
import ActionMenu from "./ActionMenu";
import moment from "moment";

const QuizCard = ({ quiz }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showActionMenu, setShowActionMenu] = useState(false);

  const modalRef = useOnClickOutsideRef(() => setShowActionMenu(false));
  console.log(quiz,"is submissiosn coming")
  return (
    <tr
      className="h-9 rounded-lg"
      style={{
        boxShadow: "0px 5px 22px 0px #0000000A",
      }}
    >
      <td className="w-[40%] py-5 px-6">
        <button
          onClick={() => {
            navigate(`/edit/${quiz.id}`);
          }}
        >
          <div className="text-[#1F204A] font-medium text-base">
            {quiz.name}
          </div>
        </button>
        <div className="text-[#90919A] text-xs items-center flex justify-start pt-2 gap-3">
          Created on: {moment(quiz.createdOn).format("DD MMM YYYY")}
          <button
            onClick={() => {
              navigate(`/share/${quiz.id}`);
            }}
            className="text-[#171055] bg-[#F6F7FA] rounded p-1"
          >
            <BsLink45Deg />
          </button>
        </div>
      </td>

      <td className="text-center">{quiz?.quiz_views || 0}</td>
      <td className="text-center">{quiz?.starts || 0}</td>
      <td className="text-center">{quiz?.submissions || 0}</td>
      <td className="text-center">
          {quiz?.starts > 0 ? `${(quiz?.submissions / quiz?.starts * 100).toFixed(1)}%` : "0%"}
      </td>      
      <td className="text-center">
        {quiz.status === "published" ? (
          <div className="px text-[#0F9A68] capitalize px-5 py-2 bg-[#34C38F]/10 rounded-full">
            {quiz.status}
          </div>
        ) : (
          <div className="px text-[#1F204A]/70 capitalize px-5 py-2 bg-[#1F204A]/5 rounded-full">
            {quiz.status}
          </div>
        )}
      </td>
      <td ref={modalRef} className="text-center relative">
        <button onClick={() => setShowActionMenu(!showActionMenu)}>
          <BiDotsVerticalRounded />
        </button>
        {showActionMenu && (
          <div className="absolute z-50 -left-32 w-40">
            <ActionMenu quizId={quiz.id} />
          </div>
        )}
      </td>
    </tr>
  );
};

export default QuizCard;
