import React, { useState } from "react";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const TotalEvents = ({ quiz, totalLeads }) => {
  const [data, setData] = useState([
    {
      name: "Views",
      count: quiz.data.quiz_views,
      fill: "#7B56F7",
    },
    {
      name: "Starts",
      count: quiz.data.starts,
      fill: "#BC94FD",
    },
    {
      name: "Completion",
      count: 0,
      fill: "#AEC0FF",
    },
    {
      name: "Leads converted",
      count: totalLeads,
      fill: "#C7FEFE",
    },
  ]);
  return (
    <div
      className="flex flex-col w-[60%] rounded-3xl p-5"
      style={{ boxShadow: "0px 5px 22px 0px #0000000A" }}
    >
      <div className="text-[#111927] text-xl font-medium ">Total Events</div>
      <div className="text-[#6C737F] text-sm">
        Analyze how your quiz is performing
      </div>
      <div className="w-full h-[350px] p-7 ">
        {data && (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data}>
              <XAxis dataKey="name" />
              {/* <YAxis /> */}
              <Tooltip />
              {/* <Legend /> */}
              <Bar dataKey="count" fill="fill" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default TotalEvents;
