import axios from "axios";

export const getLeadsApi = async (
  quizId,
  pageNo = 1,
  searchQuery = null,
  startDate = null,
  endDate = null
) => {
  try {
    let queryParams = "";
    queryParams = queryParams + "page=" + pageNo;

    if (searchQuery) queryParams = queryParams + "&search=" + searchQuery;
    if (startDate) queryParams = queryParams + "&start_date=" + startDate;
    if (endDate) queryParams = queryParams + "&end_date=" + endDate;

    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/${quizId}/leads?${queryParams}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getResultsApi = async (
  quizId,
  pageNo = 1,
  searchQuery = null,
  startDate = null,
  endDate = null
) => {
  try {
    let queryParams = "";
    queryParams = queryParams + "page=" + pageNo;

    if (searchQuery) queryParams = queryParams + "&search=" + searchQuery;
    if (startDate) queryParams = queryParams + "&start_date=" + startDate;
    if (endDate) queryParams = queryParams + "&end_date=" + endDate;

    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/${quizId}/results?${queryParams}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
