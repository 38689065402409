import React from "react";
import { useSelector } from "react-redux";

import { Navigate, Outlet } from "react-router-dom";

const PublicRoutes = () => {
  const user = useSelector((state) => state.user);

  const useAuth = (token) => {
    if (token) {
      return true;
    }
    return false;
  };

  const isAuth = useAuth(user?.accessToken);

  return isAuth ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoutes;
