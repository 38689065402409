import React from "react";
import Icon from "../../../../assets/images/logo_name.png";
const NavLogoName = () => {
  return (
    <div className="flex flex-row items-center w-fit gap-1">
      <a className="flex flex-row items-center w-full gap-1" href="/">
        <img src={Icon} alt="logo" className="h-10" />
      </a>
    </div>
  );
};

export default NavLogoName;
