import * as React from "react";
import Navbar from "../../App/comp/Navbar/Navbar";

function Pricing() {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <div className="flex flex-col w-full h-full bg-white ">
      <Navbar />
      <div className="flex w-full gap-4 items-center justify-center py-12">
        <a
          href="https://billing.stripe.com/p/login/test_dR65n38kVdZ98x28ww"
          target="_blank"
          rel="noreferrer"
          className="bg-indigo-800 px-4 py-1 rounded-md text-white"
        >
          Manage Billing
        </a>
      </div>
      <stripe-pricing-table
        pricing-table-id="prctbl_1OMnQKSCMv7H9W8lEmWYxUbe"
        publishable-key="pk_test_51NyGxoSCMv7H9W8lqLfnDeyfLTWLtLoo9siueYvfqYzKtCCageS8DfhPzOglzR6TKJW8VxLeEfaX7BXmZUYsJK6T00zQY6zRTn"
      ></stripe-pricing-table>
    </div>
  );
}

export default Pricing;
