import axios from "axios";
import { toast } from "react-toastify";

export const loginApi = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/users/login/`,
      {
        email: data.email,
        password: data.password,
      }
    );
    return res;
  } catch (error) {
    return false;
  }
};

export const registerApi = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/users/register/`,
      {
        name: data.name,
        email: data.email,
        password: data.password,
      }
    );
    return res;
  } catch (error) {
    if (error?.response?.data?.message) {
      Object.entries(error?.response?.data?.message).map((entry) => {
        console.log(entry);
        toast.error(entry[1]);
        return true;
      });
    }

    return false;
  }
};

export const tokenRefreshApi = async (data) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/users/token/refresh/`,

      {
        refresh: data.refresh,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    return false;
  }
};

export const resendVerificationApi = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/users/resend-verification/`,
      {
        email: data.email,
      }
    );
    return res;
  } catch (error) {
    if (error?.response?.data?.message) {
      Object.entries(error?.response?.data?.message).map((entry) => {
        console.log(entry);
        toast.error(entry[1]);
        return false;
      });
    }

    return false;
  }
};

export const forgotPassApi = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/users/password_reset/`,
      {
        email: data.email,
      }
    );
    return res;
  } catch (error) {
    if (error?.response?.data?.message) {
      Object.entries(error?.response?.data?.message).map((entry) => {
        console.log(entry);
        toast.error(entry[1]);
        return false;
      });
    }

    return false;
  }
};

export const resetPassApi = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/users/password_reset_confirm/`,
      {
        uid: data.uid,
        token: data.token,
        new_password: data.new_password,
      }
    );
    return res;
  } catch (error) {
    if (error?.response?.data?.message) {
      Object.entries(error?.response?.data?.message).map((entry) => {
        console.log(entry);
        toast.error(entry[1]);
        return false;
      });
    }

    return false;
  }
};
export const updatePassApi = async (data) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/users/change_password/`,
      {
        old_password: data.old_password,
        new_password: data.new_password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    if (error?.response?.data?.message) {
      Object.entries(error?.response?.data?.message).map((entry) => {
        console.log(entry);
        toast.error(entry[1]);
        return false;
      });
    }

    return false;
  }
};
export const deleteAccountApi = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.delete(
      `${process.env.REACT_APP_API_ENDPOINT}/users/delete-user/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    if (error?.response?.data?.message) {
      Object.entries(error?.response?.data?.message).map((entry) => {
        console.log(entry);
        toast.error(entry[1]);
        return false;
      });
    }

    return false;
  }
};
