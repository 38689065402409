import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import ProtectedRoutes from "./comp/routeProtection/ProtectedRoutes";
import PublicRoutes from "./comp/routeProtection/PublicRoutes";
import Toast from "./comp/Toast/Toast";
import Home from "../Pages/Home/Home";
import Error from "../Pages/Error/Error";
import Signin from "../Pages/Signin/Signin";
import Signup from "../Pages/Signup/Signup";
import EditQuiz from "../Pages/EditQuiz/EditQuiz";
import Preview from "../Pages/Preview/Preview";
import ShareQuiz from "../Pages/ShareQuiz/ShareQuiz";
import Pricing from "../Pages/Pricing/Pricing";
import PaymentVarification from "../Pages/PaymentVarification/PaymentVarification";
import Analytics from "../Pages/Analytics/Analytics";
import Settings from "../Pages/Settings/Settings";
import ForgotPass from "../Pages/ForgotPass/ForgotPass";
import ResetPass from "../Pages/ResetPass/ResetPass";
import VerifyEmail from "../Pages/VerifyEmail/VerifyEmail";

function App() {
  return (
    <div className="font-outfit">
      {/* <div>
        {"User : " +
          JSON.stringify(user) +
          "\n" +
          "Quizzes : " +
          JSON.stringify(quizzes) +
          "\n" +
          "Settings : " +
          JSON.stringify(settings) +
          "\n"}
      </div> */}
      <Toast />
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          {/* <Route path="/" element={<PricingProtectedRoutes />}> */}
          <Route path="/" element={<Home />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/payment/status" element={<PaymentVarification />} />
          <Route path="/edit/:id" element={<EditQuiz />} />
          <Route path="/share/:id" element={<ShareQuiz />} />
          <Route path="/preview/:id" element={<Preview />} />
          <Route path="/analytics/:id" element={<Analytics />} />
          <Route path="*" element={<Error />} />
          {/* </Route> */}
          <Route path="/pricing" element={<Pricing />} />
        </Route>
        <Route path="/auth" element={<PublicRoutes />}>
          <Route path="/auth/signin" element={<Signin />} />
          <Route path="/auth/signup" element={<Signup />} />
          <Route path="*" element={<Navigate to="/auth/signin" />} />
        </Route>{" "}
        <Route path="/forgotpass" element={<ForgotPass />} />
        <Route path="/resetpass" element={<ResetPass />} />
        <Route path="/verifyemail" element={<VerifyEmail />} />
      </Routes>
    </div>
  );
}

export default App;
