import React from "react";
import RecentLeads from "./comp/RecentLeads";
import TotalEvents from "./comp/TotalEvents";
import ConversionRate from "./comp/ConversionRate";

const Overview = ({ quiz, leads, results }) => {
  return (
    <>
      {results.results.length < 1 ? (
        <div className="flex  w-full items-center justify-center p-24">
          No Data Recieved
        </div>
      ) : (
        <div className="flex flex-col w-full gap-8">
          <div className="flex w-full gap-8">
            <TotalEvents quiz={quiz} totalLeads={leads?.count || 0} />
            <ConversionRate quiz={quiz} totalLeads={leads?.count || 0} />
          </div>
          <RecentLeads leads={leads} />
        </div>
      )}
    </>
  );
};

export default Overview;
