import { MdDelete } from "react-icons/md";
import React, { useState } from "react";
import ToggleSelectedQue from "../ToggleSelectedQue";
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { indexToAlphabet, updateQuestion } from "../../../../../Helpers/utils";
import {
  changeQuestionTitle,
  replaceQuizQuestion,
  toggleQuestionMenuItem,
  updateSelectedQuestion,
} from "../../../../../State/Redux/features/quizzes/quizzesSlice";

const LeadGenForm = ({ question }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [showSave, setShowSave] = useState(true);
  const [showFormBlocks, setShowFormBlocks] = useState(false);

  const handleTitleQuestionUpdate = (e) => {
    dispatch(
      changeQuestionTitle({
        quizId: params.id,
        questionIndex: question.index,
        newTitle: e.target.value,
      })
    );
    setShowSave(true);
  };

  const toggleMenuItem = (toggleMenu) => {
    dispatch(
      toggleQuestionMenuItem({
        quizId: params.id,
        questionIndex: question.index,
        toggleMenu,
      })
    );
    setShowSave(true);
  };

  const handleFormBlockTitleChange = (index, event) => {
    const newQuestion = JSON.parse(JSON.stringify(question));
    newQuestion.value.data.formBlocks[index].title = event.target.value;

    dispatch(
      replaceQuizQuestion({
        quizId: params.id,
        questionIndex: question.index,
        newQuestion: newQuestion.value,
      })
    );
    setShowSave(true);
  };
  const handleFormBlockPlaceholderChange = (index, event) => {
    const newQuestion = JSON.parse(JSON.stringify(question));
    newQuestion.value.data.formBlocks[index].placeholder = event.target.value;

    dispatch(
      replaceQuizQuestion({
        quizId: params.id,
        questionIndex: question.index,
        newQuestion: newQuestion.value,
      })
    );
    setShowSave(true);
  };

  const handleFormBlockDelete = (index) => {
    const newQuestion = JSON.parse(JSON.stringify(question));
    newQuestion.value.data.formBlocks.splice(index, 1);
    dispatch(
      replaceQuizQuestion({
        quizId: params.id,
        questionIndex: question.index,
        newQuestion: newQuestion.value,
      })
    );
    setShowSave(true);
  };

  const handleAddFormBlock = (form) => {
    const newQuestion = JSON.parse(JSON.stringify(question));
    newQuestion.value.data.formBlocks.push(form);
    dispatch(
      replaceQuizQuestion({
        quizId: params.id,
        questionIndex: question.index,
        newQuestion: newQuestion.value,
      })
    );
    setShowSave(true);
    setShowFormBlocks((prev) => !prev);
  };

  const handleSaveToDraft = async () => {
    const res = await updateQuestion(question.value, params.id);
    if (res) {
      setShowSave(false);
      dispatch(
        updateSelectedQuestion({
          quizId: params.id,
          questionIndex: null,
        })
      );
    }
  };
  return (
    <div className="w-full h-fit flex flex-col border-[1px] rounded-lg ">
      <div className="bg-[#F6F7FA] rounded-t-lg px-5 py-3 flex w-full items-center justify-between">
        <ToggleSelectedQue question={question} />

        <div className="flex gap-2 w-fit h-full">
          {question.value.data.toggleMenus.map((toggleMenu) => {
            return (
              <div className="flex gap-2 items-center justify-center">
                <div className="font-medium text-[#1F204A] text-sm">
                  {toggleMenu.title}
                </div>

                <Switch
                  height={15}
                  width={27}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  offColor={"#BEC6D3"}
                  onColor={"#4A3AFF"}
                  onHandleColor={"#ffffff"}
                  offHandleColor={"#ffffff"}
                  checked={toggleMenu.value}
                  onChange={() => toggleMenuItem(toggleMenu)}
                />
              </div>
            );
          })}
          {showSave ? (
            <button
              onClick={handleSaveToDraft}
              className="px-3 py-[6px] h-fit rounded bg-[#171055] text-white font-medium text-sm"
            >
              Save
            </button>
          ) : (
            <div
              onClick={handleSaveToDraft}
              className="h-full items-center flex justify-center opacity-50"
            >
              Saved
            </div>
          )}
        </div>
      </div>
      <div className="px-5 pt-2 text-[#90919A]">Title</div>

      <div className="px-5 py-2">
        <textarea
          className="w-full min-h-[40px] items-start justify-start flex text-start ring-0 focus:ring-0 focus:outline-none"
          type="text"
          value={question.value.data.title}
          onChange={(e) => {
            handleTitleQuestionUpdate(e);
          }}
          placeholder="Enter your details below for us to get in touch with you."
        />
      </div>
      <div className="px-5 py-2 pb-4 flex flex-col gap-3">
        {question.value.data.formBlocks.map((block, index) => {
          return (
            <div className="flex gap-3 group">
              <div className="flex flex-col gap-2 border-[1px] rounded-lg border-[#000000]/10 p-3 bg-white w-[90%] truncate  items-start justify-start">
                <div className="text-sm text-[#90919A] "> {block.name}</div>
                <div className="flex w-full border-[1px] border-[#EFF0F6] rounded-md">
                  <div className="text-center text-sm h-full flex items-center justify-center px-11 py-2 text-[#90919A] bg-[#F6F7FA]">
                    {" "}
                    TITLE{" "}
                  </div>
                  <input
                    value={block.title}
                    type="text"
                    placeholder="Option"
                    onChange={(e) => handleFormBlockTitleChange(index, e)}
                    className="pr-4 text-base w-full pl-4 py-2 text-start bg-transparent ring-0 focus:outline-none focus:ring-0"
                  />
                </div>
                <div className="flex w-full border-[1px] border-[#EFF0F6] rounded-md">
                  <div className="text-center text-sm h-full flex items-center justify-center px-2 py-2 text-[#90919A] bg-[#F6F7FA]">
                    PLACEHOLDER
                  </div>
                  <input
                    value={block.placeholder}
                    type="text"
                    placeholder="Option"
                    onChange={(e) => handleFormBlockPlaceholderChange(index, e)}
                    className="pr-4 text-base w-full pl-4 py-2 text-start bg-transparent ring-0 focus:outline-none focus:ring-0"
                  />
                </div>
              </div>

              <button
                onClick={() => handleFormBlockDelete(index)}
                className="text-red-400 text-xl font-extrabold hover:text-red-500 hidden group-hover:flex h-full items-center justify-center"
              >
                <MdDelete />
              </button>
            </div>
          );
        })}
        <div className="flex relative">
          <button
            onClick={() => setShowFormBlocks((prev) => !prev)}
            className="flex gap-2 items-start justify-start bg-white rounded-sm py-1 underline-offset-2 underline  w-fit cursor-pointer text-[#4A3AFF]"
          >
            <div className="text-sm font-medium min-w-[80px] text-start">
              Add form blocks
            </div>
          </button>
          <div className="absolute top-[40px] left-0">
            {showFormBlocks && (
              <div className="flex flex-col p-3 rounded-lg shadow-sm z-[50] bg-white min-w-[150px] border-[1px]">
                <div className="flex text-sm text-[#90919A] font-normal pb-2">
                  Form Blocks
                </div>
                <div className="flex flex-col gap-[2px]">
                  {question.value.formBlocks.map((form) => {
                    return (
                      <button
                        onClick={() => handleAddFormBlock(form)}
                        className="flex rounded-lg p-2 hover:bg-[#F6F7FA] gap-3 group"
                      >
                        <div className="p-1  rounded flex items-center justify-center bg-[#F6F7FA] group-hover:bg-white">
                          <img
                            className="h-4 w-4"
                            src={form.icon}
                            alt={form.name}
                          />
                        </div>
                        <div>{form.name}</div>
                      </button>
                    );
                  })}
                </div>
              </div>
            )}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadGenForm;
