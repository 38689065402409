import { BiArrowBack, BiLoader } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import Navbar from "../../App/comp/Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { getLeadsApi, getResultsApi } from "../../APIs/analytics";
import Overview from "./components/overview/Overview";
import Leads from "./components/leads/Leads";
import Responses from "./components/responses/Responses";
import { useSelector } from "react-redux";

const getTab = (tabName, quiz, leads, results) => {
  switch (tabName) {
    case "overview":
      return <Overview quiz={quiz} leads={leads} results={results} />;
    case "leads":
      return <Leads />;
    case "responses":
      return <Responses />;
    default:
      return <div>failed to get tab</div>;
  }
};

const Analytics = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [leads, setLeads] = useState(null);
  const [results, setResults] = useState(null);
  const [currentTab, setCurrentTab] = useState("overview");
  const quizzes = useSelector((state) => state.quizzes).list;

  const [quiz, setQuiz] = useState({
    data: quizzes.filter((quiz) => quiz.id === params.id)[0],
  });

  useEffect(() => {
    setQuiz({
      data: quizzes.filter((quiz) => quiz.id === params.id)[0],
    });
    console.log("quizes");
  }, [quizzes, params.id]);

  const fetchAndSetLeads = async () => {
    const res = await getLeadsApi(params.id);
    setLeads(res.data);
  };
  const fetchAndSetResults = async () => {
    const res = await getResultsApi(params.id);
    setResults(res.data);
  };

  useEffect(() => {
    fetchAndSetLeads();
    fetchAndSetResults();
  }, []);

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <Navbar />

      {leads && results ? (
        <div className="flex flex-col p-8">
          <div className="flex gap-2 items-center pb-5">
            <button
              onClick={() => navigate(-1)}
              className="text-center h-full p-2 bg-[#1F204A]/5 rounded-full text-[#90919A]"
            >
              <BiArrowBack />
            </button>
            <div className="text-[#1F204A] text-lg font-semibold">
              {quiz?.data?.name}
            </div>
            <div className="text-[#90919A] text-lg">|</div>
            <div className="text-[#90919A] text-lg">{quiz?.data?.id}</div>
          </div>
          <div className="flex gap-6 text-[#90919A] pb-7">
            <button
              style={
                currentTab === "overview"
                  ? {
                      color: "#4A3AFF",
                      fontWeight: "500",
                      textDecorationLine: "underline",
                      textUnderlineOffset: "6px",
                    }
                  : {}
              }
              onClick={() => setCurrentTab("overview")}
            >
              Overview
            </button>
            <button
              style={
                currentTab === "responses"
                  ? {
                      color: "#4A3AFF",
                      fontWeight: "500",
                      textDecorationLine: "underline",
                      textUnderlineOffset: "6px",
                    }
                  : {}
              }
              onClick={() => setCurrentTab("responses")}
            >
              Responses
            </button>
            <button
              style={
                currentTab === "leads"
                  ? {
                      color: "#4A3AFF",
                      fontWeight: "500",
                      textDecorationLine: "underline",
                      textUnderlineOffset: "6px",
                    }
                  : {}
              }
              onClick={() => setCurrentTab("leads")}
            >
              Leads
            </button>
          </div>
          {getTab(currentTab, quiz, leads, results)}
        </div>
      ) : (
        <div className="w-full h-full items-center justify-center text-center py-48">
          <div className="flex items-center justify-center gap-2">
            <div className="animate-spin w-fit h-fit text-3xl">
              <BiLoader />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
