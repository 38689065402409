import { nanoid } from "nanoid";
import { createQuizApi } from "../../../../APIs/quiz";
import {
  getPlatformQuizzesState,
  setPlatformQuizzesState,
} from "../../../../Helpers/platformState";
import {
  convertISOtoTimestamp,
  deleteQuestionStateReorder,
} from "../../../../Helpers/utils";
import { updateOrderQuestionsApi } from "../../../../APIs/question";
const { createSlice } = require("@reduxjs/toolkit");

const initialState = getPlatformQuizzesState();

export const quizzesSlice = createSlice({
  name: "quizzes",
  initialState,
  reducers: {
    // no params required
    createQuiz: (state, action) => {
      const quizId = nanoid();
      const quiz = {
        id: action.payload.id || quizId,
        name: action.payload.name,
        description: action.payload.description,
        content_type: action.payload.content_type,
        questions: action.payload.questions,
        modifiedOn: Date.now(),
        createdOn: Date.now(),
        selectedQuestionIndex: action.payload.selectedQuestionIndex,
        status: action.payload.status,
      };

      const newState = {
        list: [...state.list, quiz],
      };

      setPlatformQuizzesState(newState);
      return newState;
    },

    updateQuiz: (state, action) => {
      const quizId = action.payload.quizId;
      const newData = action.payload.newData;
      let newState = JSON.parse(JSON.stringify(state));

      newState.list = newState.list.map((quiz, index) => {
        if (quiz.id === quizId) {
          return { ...quiz, ...newData };
        } else return quiz;
      });
      console.log(newState);
      setPlatformQuizzesState(newState);
      return newState;
    },

    deleteQuiz: (state, action) => {
      const quizId = action.payload;
      const newState = {
        list: state.list.filter((quiz) => quiz.id !== quizId),
      };

      setPlatformQuizzesState(newState);
      return newState;
    },

    replaceQuizzes: (state, action) => {
      console.log(action.payload);
      const newState = {
        list: action.payload,
      };
      setPlatformQuizzesState(newState);
      return newState;
    },

    sortQuizzes: (state, action) => {
      const sortType = action.payload.sortType;

      let newState = JSON.parse(JSON.stringify(state));
      if (sortType === "name-asc") {
        newState.list = newState.list.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }
      if (sortType === "name-desc") {
        newState.list = newState.list.sort((a, b) =>
          b.name.localeCompare(a.name)
        );
      }
      if (sortType === "date-asc") {
        newState.list = newState.list.sort((a, b) => a.createdOn - b.createdOn);
      }
      if (sortType === "date-desc") {
        newState.list = newState.list.sort((a, b) => b.createdOn - a.createdOn);
      }

      setPlatformQuizzesState(newState);
      return newState;
    },

    addQuizQuestion: (state, action) => {
      const quizId = action.payload.quizId;
      const question = action.payload.question;
      console.log(quizId);
      console.log(question);

      let newState = JSON.parse(JSON.stringify(state));

      newState.list = newState.list.map((quiz) => {
        console.log(quiz);
        if (quiz.id === quizId) {
          quiz.questions.splice(quiz.questions.length - 1, 0, question);
          quiz.modifiedOn = Date.now();
          quiz.selectedQuestionIndex = quiz.questions.length - 2;
        }
        return quiz;
      });
      console.log(newState);
      setPlatformQuizzesState(newState);
      return newState;
    },

    changeQuestionTitle: (state, action) => {
      const quizId = action.payload.quizId;
      const questionIndex = action.payload.questionIndex;
      const newTitle = action.payload.newTitle;

      let newState = JSON.parse(JSON.stringify(state));

      newState.list = newState.list.map((quiz) => {
        console.log(quiz);
        if (quiz.id === quizId) {
          quiz.questions[questionIndex].data.title = newTitle;
          quiz.modifiedOn = Date.now();
        }
        return quiz;
      });
      setPlatformQuizzesState(newState);
      return newState;
    },

    changeQuestionRating: (state, action) => {
      const quizId = action.payload.quizId;
      const questionIndex = action.payload.questionIndex;
      const newmax_rating = action.payload.newmax_rating;

      let newState = JSON.parse(JSON.stringify(state));

      newState.list = newState.list.map((quiz) => {
        console.log(quiz);
        if (quiz.id === quizId) {
          quiz.questions[questionIndex].data.max_rating = newmax_rating;
          quiz.modifiedOn = Date.now();
        }
        return quiz;
      });
      setPlatformQuizzesState(newState);
      return newState;
    },

    replaceQuizQuestion: (state, action) => {
      const quizId = action.payload.quizId;
      const questionIndex = action.payload.questionIndex;
      const newQuestion = action.payload.newQuestion;

      let newState = JSON.parse(JSON.stringify(state));
      newState.list = newState.list.map((quiz) => {
        console.log(quiz);
        if (quiz.id === quizId) {
          quiz.questions[questionIndex] = newQuestion;
          quiz.modifiedOn = Date.now();
        }
        return quiz;
      });
      setPlatformQuizzesState(newState);
      return newState;
    },

    updateSelectedQuestion: (state, action) => {
      const quizId = action.payload.quizId;
      const questionIndex = action.payload.questionIndex;
      console.log(quizId);
      console.log(questionIndex);
      let newState = JSON.parse(JSON.stringify(state));

      newState.list = newState.list.map((quiz) => {
        console.log(quiz);
        if (quiz.id === quizId) {
          quiz.selectedQuestionIndex = questionIndex;
        }
        return quiz;
      });
      console.log(newState);
      setPlatformQuizzesState(newState);
      return newState;
    },

    questionDragDrop: (state, action) => {
      const quizId = action.payload.quizId;
      const sourceQuestionIndex = action.payload.sourceQuestionIndex;
      const destinationQuestionIndex = action.payload.destinationQuestionIndex;
      let newState = JSON.parse(JSON.stringify(state));
      newState.list = newState.list.map((quiz) => {
        if (quiz.id === quizId) {
          console.log(sourceQuestionIndex, destinationQuestionIndex);
          console.log(quiz);
          const [draggedQuestion] = quiz.questions.splice(
            sourceQuestionIndex,
            1
          );
          console.log(draggedQuestion);
          const orderedQuestionIds = [];

          // Insert the question at the destination index
          quiz.questions.splice(destinationQuestionIndex, 0, draggedQuestion);
          quiz.questions = quiz.questions.map((question, index) => {
            question.order = index + 1;
            orderedQuestionIds.push(question.id);
            return question;
          });

          updateOrderQuestionsApi({
            question_ids: orderedQuestionIds,
          });
          quiz.modifiedOn = Date.now();

          console.log(quiz, orderedQuestionIds);
        }
        return quiz;
      });
      console.log(newState);
      setPlatformQuizzesState(newState);
      return newState;
    },

    deleteQuestion: (state, action) => {
      const quizId = action.payload.quizId;
      const questionIndex = action.payload.questionIndex;
      console.log(quizId);
      console.log(questionIndex);
      let newState = JSON.parse(JSON.stringify(state));

      newState.list = newState.list.map((quiz) => {
        if (quiz.id === quizId) {
          console.log(quiz);
          quiz.questions.splice(questionIndex, 1);
          // quiz.selectedQuestionIndex = quiz.questions.length - 1;
          quiz.modifiedOn = Date.now();

          const newQuestions = deleteQuestionStateReorder(
            questionIndex + 1,
            quiz.questions
          );
          const orderedQuestionIds = newQuestions.map(
            (question) => question.id
          );
          updateOrderQuestionsApi({
            question_ids: orderedQuestionIds,
          });
          console.log(newQuestions);
        }
        return quiz;
      });
      console.log(newState);
      setPlatformQuizzesState(newState);
      return newState;
    },

    toggleQuestionMenuItem: (state, action) => {
      const quizId = action.payload.quizId;
      const questionIndex = action.payload.questionIndex;
      const toggleMenu = action.payload.toggleMenu;
      console.log(quizId);
      console.log(questionIndex);
      console.log(toggleMenu);
      let newState = JSON.parse(JSON.stringify(state));

      newState.list = newState.list.map((quiz) => {
        console.log(quiz);
        if (quiz.id === quizId) {
          quiz.questions[questionIndex].data.toggleMenus.map((menu) => {
            if (menu.title === toggleMenu.title) {
              menu.value = !menu.value;
            }
            return menu;
          });
          quiz.modifiedOn = Date.now();
        }
        return quiz;
      });
      console.log(newState);
      setPlatformQuizzesState(newState);
      return newState;
    },

    replaceQuestions: (state, action) => {
      const quizId = action.payload.quizId;
      const questions = action.payload.questions;
      console.log(quizId);
      console.log(questions);

      let newState = JSON.parse(JSON.stringify(state));

      newState.list = newState.list.map((quiz) => {
        console.log(quiz);
        if (quiz.id === quizId) {
          quiz.questions = questions;
          quiz.modifiedOn = Date.now();

          quiz.selectedQuestionIndex = 0;
        }
        return quiz;
      });
      console.log(newState);
      setPlatformQuizzesState(newState);
      return newState;
    },

    reCalcQuestionOrders: (state, action) => {
      const quizId = action.payload.quizId;

      let newState = JSON.parse(JSON.stringify(state));

      newState.list = newState.list.map((quiz) => {
        if (quiz.id === quizId) {
          const orderedQuestionIds = quiz.questions.map((question, index) => {
            quiz.questions[index].order = index + 1;
            return question.id;
          });
          updateOrderQuestionsApi({
            question_ids: orderedQuestionIds,
          });
        }
        return quiz;
      });
      console.log(newState);
      setPlatformQuizzesState(newState);
      return newState;
    },

    setQuizzesFromPlatform: (state, action) => {
      const quizzes = action.payload;
      return quizzes;
    },
  },
});

export const {
  createQuiz,
  updateQuiz,
  deleteQuiz,
  replaceQuizzes,
  sortQuizzes,
  addQuizQuestion,
  changeQuestionTitle,
  changeQuestionRating,
  replaceQuizQuestion,
  questionDragDrop,
  deleteQuestion,
  toggleQuestionMenuItem,
  updateSelectedQuestion,
  replaceQuestions,
  reCalcQuestionOrders,
  setQuizzesFromPlatform,
} = quizzesSlice.actions;

export default quizzesSlice.reducer;
