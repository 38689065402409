import { AiOutlineMobile } from "react-icons/ai";
import { AiOutlineDesktop } from "react-icons/ai";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSettings } from "../../../State/Redux/features/settings/settingsSlice";

const PreviewTypeSelector = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  const togglePreviewType = (newPreviewType) => {
    dispatch(updateSettings({ previewType: newPreviewType }));
  };
  return (
    <div className="w-full h-fit flex items-center justify-center text-center">
      <div className="bg-white w-fit h-full rounded-full flex items-center justify-center p-1 text-[#1F204A] text-base font-normal">
        <button
          style={
            settings?.previewType === "desktop"
              ? {
                  background: "#1F204A0D",
                }
              : {
                  color: "#90919A",
                }
          }
          onClick={() => togglePreviewType("desktop")}
          className="flex gap-2 items-center justify-center rounded-full h-full px-3 py-1"
        >
          <AiOutlineDesktop /> Desktop
        </button>
        <button
          style={
            settings?.previewType === "mobile"
              ? {
                  background: "#1F204A0D",
                }
              : {
                  color: "#90919A",
                }
          }
          onClick={() => togglePreviewType("mobile")}
          className="flex gap-2 items-center justify-center rounded-full h-full px-3 py-1"
        >
          <AiOutlineMobile /> Mobile
        </button>
      </div>
    </div>
  );
};

export default PreviewTypeSelector;
