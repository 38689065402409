import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../State/Redux/features/user/userSlice";
import { tokenRefreshApi } from "../../../APIs/auth";
import { replaceQuizzes } from "../../../State/Redux/features/quizzes/quizzesSlice";

const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const isAccessTokenValid = (accessToken) => {
    var decodedAccessToken = jwt_decode(accessToken);

    if (decodedAccessToken.exp < Date.now() / 1000) {
      return false;
    }
    return true;
  };
  const isRefreshTokenValid = (refreshToken) => {
    var decodedRefreshToken = jwt_decode(refreshToken);

    if (decodedRefreshToken.exp < Date.now() / 1000) {
      return false;
    }
    return true;
  };

  const updateAccessToken = async (refreshToken) => {
    const res = await tokenRefreshApi({
      refresh: refreshToken,
    });
  };

  const checkAuth = () => {
    if (user?.accessToken) {
      if (!isAccessTokenValid(user.accessToken)) {
        if (!isRefreshTokenValid(user.accessToken)) {
          dispatch(replaceQuizzes([]));
          dispatch(logout());
          return false;
        } else {
          updateAccessToken(user.accessToken);
          return true;
        }
      } else {
        return true;
      }
    }

    return false;
  };

  const isAuth = checkAuth();

  return isAuth ? <Outlet /> : <Navigate to="/auth/signin" replace />;
};

export default ProtectedRoutes;
