import React from "react";
import { useTable } from "react-table";

const LeadsTable = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  return (
    <table
      style={{
        borderCollapse: "collapse",
      }}
      {...getTableProps()}
      className="w-full rounded-2xl"
    >
      <thead className="text-[#1F204A] text-sm text-start bg-[#F6F7FA]">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th className="p-4 text-start" {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="border-b-[1px] border-x-[1px] border-[#F6F7FA]"
            >
              {row.cells.map((cell) => (
                <td className="py-4 pl-2" {...cell.getCellProps()}>
                  {" "}
                  {cell.render("Cell")}{" "}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default LeadsTable;
