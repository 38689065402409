import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "./comp/Header";
import Create from "./ContentCreation/Create";
import View from "./ContentView/View";
import { getQuestionsApi } from "../../APIs/question";
import {
  replaceQuestions,
  updateQuiz,
} from "../../State/Redux/features/quizzes/quizzesSlice";
import { getQuestionsApiToReduxQuestionsList } from "../../Helpers/ReduxApiBridge";
import { BiLoader } from "react-icons/bi";
import { getQuizApi } from "../../APIs/quiz";
import { convertISOtoTimestamp } from "../../Helpers/utils";

const EditQuiz = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const quizzes = useSelector((state) => state.quizzes).list;

  const [quiz, setQuiz] = useState({
    data: quizzes.filter((quiz) => quiz.id === params.id)[0],
  });

  const [loading, setLoading] = useState(true);

  const fetchAndSetQuizQuestions = async () => {
    setLoading(true);
    const res = await getQuestionsApi(params.id);
    const questions = getQuestionsApiToReduxQuestionsList(res.data);
    dispatch(
      replaceQuestions({
        quizId: params.id,
        questions: questions,
      })
    );
    setLoading(false);
  };
  const fetchAndUpdateQuiz = async () => {
    setLoading(true);
    const res = await getQuizApi(params.id);
    dispatch(
      updateQuiz({
        quizId: params.id,
        newData: {
          id: res.data.uuid,
          name: res.data.name,
          description: res.data.description,

          modifiedOn: convertISOtoTimestamp(res.data.modified_on),
          createdOn: convertISOtoTimestamp(res.data.created_on),

          status: res.data.status,
        },
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    setQuiz({
      data: quizzes.filter((quiz) => quiz.id === params.id)[0],
    });
    console.log("quizes");
  }, [quizzes, params.id]);

  useEffect(() => {
    fetchAndSetQuizQuestions();
    fetchAndUpdateQuiz();
  }, []);

  return (
    <div className="flex w-screen h-screen">
      {quiz.data ? (
        <div className="flex w-full h-full flex-col">
          <Header />
          {!loading ? (
            <div className="flex flex-row w-full h-full ">
              <Create />
              <hr className="w-0.5 h-full bg-[#F8F8F8]" />
              <View />
            </div>
          ) : (
            <div className="w-full h-full items-center justify-center text-center py-48">
              <div className="flex items-center justify-center gap-2">
                <div className="animate-spin w-fit h-fit text-3xl">
                  <BiLoader />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex w-screen h-screen items-center justify-center text-3xl ">
          Quiz not found
        </div>
      )}
    </div>
  );
};

export default EditQuiz;
