import { BiLoader } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createQuiz } from "../../../State/Redux/features/quizzes/quizzesSlice";
import { questionList } from "../../../config/questions";
import { createQuizApi } from "../../../APIs/quiz";
import { toast } from "react-toastify";
import { createQuestionsApi } from "../../../APIs/question";
import { useNavigate } from "react-router-dom";

const CreateQuizPopup = ({ closePopup }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [quiz, setQuiz] = useState({
    name: "",
    description: "",
    content_type: "outcome",
    questions: [questionList[0], questionList[1]],
    selectedQuestionIndex: 0,
  });

  const handleCreateQuiz = async () => {
    try {
      setIsLoading(true);
      const res = await createQuizApi({
        name: quiz.name,
        description: quiz.description,
        quiz_type: quiz.content_type,
      });

      await createQuestionsApi(res.data.uuid, {
        ...questionList[0],
        order: 1,
      });
      await createQuestionsApi(res.data.uuid, {
        ...questionList[1],
        order: 2,
      });

      dispatch(createQuiz({ ...quiz, id: res.data.uuid }));
      navigate(`/edit/${res.data.uuid}`);
    } catch (error) {
      toast.error("falied to create quiz");
    }
    // closePopup();
  };
  return (
    <div
      className="w-screen fixed top-0 right-0 h-screen bg-[#0D0D25]/90 z-50 flex items-center justify-center"
      onClick={closePopup}
    >
      <div
        className="flex items-start justify-start w-1/2 h-fit relative bg-white rounded-[40px] px-20 py-12"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={closePopup}
          className="absolute right-6 top-6 text-2xl"
        >
          <CgClose />
        </button>
        <div>
          <div className="text-2xl font-medium">Create Quiz</div>
          <div className="flex flex-col gap-2 pt-7">
            <div className="text-[#1F204A] text-sm font-medium">Name</div>
            <input
              className="w-[400px] h-[40px] rounded-[8px] border-[1px] border-[#EFF0F6] px-4 py-2"
              type="text"
              placeholder="Type quiz name here"
              value={quiz.name}
              onChange={(e) => setQuiz({ ...quiz, name: e.target.value })}
            />
          </div>
          <div className="flex flex-col gap-2 pt-7">
            <div className="text-[#1F204A] text-sm font-medium">
              Description
            </div>
            <input
              className="w-[400px] h-[40px] rounded-[8px] border-[1px] border-[#EFF0F6] px-4 py-2"
              type="text"
              placeholder="Your quiz description goes here.."
              value={quiz.description}
              onChange={(e) =>
                setQuiz({ ...quiz, description: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col gap-2 pt-7">
            <div className="text-[#1F204A] text-sm font-medium">
              Content type
            </div>

            <div className="flex gap-4">
              <div
                style={
                  quiz.content_type === "outcome"
                    ? {
                        borderColor: "#171055",
                        background: "rgba(31, 32, 74, 0.05)",
                      }
                    : {}
                }
                className="border-[1px] text-[#EFF0F6] rounded-lg px-5 py-4 cursor-pointer flex flex-col flex-wrap gap-2"
                onClick={() => setQuiz({ ...quiz, content_type: "outcome" })}
              >
                <label className="text-[#1F204A] font-medium gap-2">
                  <input
                    className="m-1"
                    name="content_type"
                    type="radio"
                    checked={quiz.content_type === "outcome"}
                  />
                  Outcome Logic: Match Answers to Outcomes
                </label>
                <div className="text-sm font-normal text-[#90919A]">
                  Results are based on the outcome with the most selected
                  answers.
                </div>
              </div>
              {/* <div
                style={
                  quiz.content_type === "scoring"
                    ? {
                        borderColor: "#171055",
                        background: "rgba(31, 32, 74, 0.05)",
                      }
                    : {}
                }
                className="border-[1px] text-[#EFF0F6] rounded-lg px-5 py-4 cursor-pointer flex flex-col flex-wrap gap-2"
                onClick={() => setQuiz({ ...quiz, content_type: "scoring" })}
              >
                <label className="text-[#1F204A] font-medium gap-2">
                  <input
                    className="m-1"
                    name="content_type"
                    type="radio"
                    checked={quiz.content_type === "scoring"}
                  />{" "}
                  Scoring Logic: Assign Scores to Answers
                </label>
                <div className="text-sm font-normal text-[#90919A]">
                  Results are determined by the range of scores assigned to the
                  selected answers.
                </div>
              </div> */}
            </div>
          </div>

          <button
            onClick={handleCreateQuiz}
            disabled={
              quiz.name === "" ||
              quiz.description === "" ||
              quiz.content_type === "" ||
              isLoading
            }
            className="mt-7 text-white bg-[#4A3AFF] disabled:opacity-30 rounded-md px-6 py-2"
          >
            {isLoading ? (
              <div className="flex items-center justify-center gap-2">
                <div className="animate-spin w-fit h-fit text-lg">
                  <BiLoader />
                </div>
                Creating Quiz..
              </div>
            ) : (
              <div>Confirm</div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateQuizPopup;
