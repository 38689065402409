import { HiOutlineMail } from "react-icons/hi";
import { FaWhatsapp } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { BiCopy } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const shareTo = [
  {
    id: "facebook",
    icon: <FaFacebookF />,
    name: "Facebook",
    redirectTo: "link",
    bgColor: "#337FFF",
  },
  {
    id: "twitter",
    icon: <FaTwitter />,
    name: "Twitter",
    redirectTo: "link",
    bgColor: "#000000",
  },
  {
    id: "linkedin",
    icon: <FaLinkedinIn />,
    name: "LinkedIn",
    redirectTo: "link",
    bgColor: "#2867B2",
  },
  {
    id: "whatsapp",
    icon: <FaWhatsapp />,
    name: "Whatsapp",
    redirectTo: "link",
    bgColor: "#00D95F",
  },
  {
    id: "email",
    icon: <HiOutlineMail />,
    name: "Email",
    redirectTo: "link",
    bgColor: "#F54180",
  },
];

const ShareLink = () => {
  const params = useParams();
  const quizzes = useSelector((state) => state.quizzes).list;

  const [quiz, setQuiz] = useState({
    data: quizzes.filter((quiz) => quiz.id === params.id)[0],
  });

  useEffect(() => {
    setQuiz({
      data: quizzes.filter((quiz) => quiz.id === params.id)[0],
    });
    console.log("quizes");
  }, [quizzes, params.id]);
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <div className="text-[#90919A]">Business survey quiz URL</div>
        <div className="flex w-full">
          <div className="border-[1px] border-[#D3D8E0]  w-full rounded-l-lg p-4 text-[#1F204A]">
            {`https://render.leadsbuddha.com/${quiz.data.id}`}
          </div>
          <button
            onClick={() => {
              navigator.clipboard.writeText(
                `https://render.leadsbuddha.com/${quiz.data.id}`
              );
              toast.success("link copied");
            }}
            className="bg-[#171055] rounded-r-lg w-fit text-white flex whitespace-nowrap items-center justify-center p-4 gap-2"
          >
            <div>
              <BiCopy />
            </div>
            <div>Copy link</div>
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-[#90919A]">Share via</div>
        <div className="flex w-full gap-3">
          {shareTo.map((shareItem) => {
            return (
              <button
                key={shareTo.id}
                style={{
                  background: shareItem.bgColor,
                }}
                className="rounded-lg text-xl w-fit text-white flex whitespace-nowrap items-center justify-center p-3"
              >
                {shareItem.icon}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ShareLink;
