import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "./comp/Header";
import Menu from "./menu/Menu";
import { getQuizShareDataApi } from "../../APIs/share";

const ShareQuiz = () => {
  const params = useParams();
  const quizzes = useSelector((state) => state.quizzes).list;

  const [quiz, setQuiz] = useState({
    data: quizzes.filter((quiz) => quiz.id === params.id)[0],
  });

  useEffect(() => {
    setQuiz({
      data: quizzes.filter((quiz) => quiz.id === params.id)[0],
    });
  }, [quizzes, params.id]);

  return (
    <div className="flex w-screen h-screen bg-[#F8F8F8]">
      {quiz.data ? (
        <div className="flex w-full h-full flex-col">
          <Header />
          <div className="flex flex-row w-full h-full px-16 py-10 ">
            <Menu />
          </div>
        </div>
      ) : (
        <div className="flex w-screen h-screen items-center justify-center text-3xl ">
          Quiz not found
        </div>
      )}
    </div>
  );
};

export default ShareQuiz;
