import axios from "axios";

export const getQuestionsApi = async (quizId) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    let res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/${quizId}/questions/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    res.data.sort((a, b) => a.order - b.order);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createQuestionsApi = async (quizId, data) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/${quizId}/save_question/`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const updateQuestionsApi = async (quizId, data) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/${quizId}/save_question/`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteQuestionApi = async (question_id) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.delete(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/question/delete/${question_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateOrderQuestionsApi = async (data) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/questions/update-order/`,
      {
        question_ids: data.question_ids,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return false;
  }
};
