import axios from "axios";

export const createQuizApi = async (data) => {
  if (data.quiz_type === "outcome") data.quiz_type = "outcome_quiz";
  if (data.quiz_type === "scoring") data.quiz_type = "scoring_quiz";
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/create_quiz/`,
      {
        name: data.name,
        description: data.description,
        quiz_type: data.quiz_type,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getQuizzesApi = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/list/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getQuizApi = async (quizId) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/${quizId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateQuizApi = async (quizId, data) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.patch(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/update/${quizId}/`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const deleteQuizApi = async (quiz_id) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.delete(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/delete/${quiz_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return false;
  }
};
