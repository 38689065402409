import React, { useState } from "react";

const Text = ({ question, index, setSelectedQueIndex }) => {
  const [answers, setAnswers] = useState(null);
  return (
    <div className="flex flex-col items-start text-center justify-start">
      <div className="text-[#90919A] text-lg text-start">Question {index}</div>

      <div className="text-[#1F204A] text-2xl font-normal pt-10 text-start">
        {question.data.title}
        {question.data?.toggleMenus?.filter(
          (menu) => menu.title === "Required"
        )?.[0]?.value === true && <span className="text-red-500 pl-1">*</span>}
      </div>
      <div className="text-[#90919A] text-base font-light pt-3">
        {question.data.desc ? question.data.desc : ""}
      </div>

      <input
        className="w-full mt-10 border-b-[2px] border-[#E8EAEC] text-[#1F204A] text-base font-normal focus:outline-none"
        placeholder=""
        value={answers}
        onChange={(e) => setAnswers(e.target.value)}
      />

      <div className="w-full flex items-start gap-1 pt-14">
        <button
          onClick={async () => {
            setSelectedQueIndex(index + 1);
          }}
          disabled={!answers}
          className="flex items-center justify-center text-base gap-2 px-6 py-2 rounded-md bg-[#2972C6] text-white text-center disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Submit
        </button>
        {question.data?.toggleMenus?.filter(
          (menu) => menu.title === "Required"
        )?.[0]?.value !== true && (
          <button
            onClick={async () => {
              setSelectedQueIndex(index + 1);
            }}
            className="flex items-center justify-center text-center text-base gap-2 px-4 py-2 "
          >
            Skip
          </button>
        )}
      </div>
    </div>
  );
};

export default Text;
