import { BiWindow } from "react-icons/bi";
import { BiCodeAlt } from "react-icons/bi";
import { BsLink45Deg } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import ShareLink from "./MenuItems/ShareLink";
import EmbedCode from "./MenuItems/EmbedCode";
import Popup from "./MenuItems/Popup";
import AcBar from "./MenuItems/AcBar";
import SideNote from "./MenuItems/SideNote";

export const MenuItems = [
  {
    id: "share_link",
    title: "Share Link",
    icon: <BsLink45Deg />,
    render: <ShareLink />,
  },
  {
    id: "embed_code",
    title: "Embed code",
    icon: <BiCodeAlt />,
    render: <EmbedCode />,
  },
  {
    id: "popup",
    title: "Popup",
    icon: <BiWindow />,
    render: <Popup />,
  },
  {
    id: "announcement_bar",
    title: "Announcement Bar",
    icon: <BiWindow />,
    render: <AcBar />,
  },
  {
    id: "side_note",
    title: "Side note",
    icon: <BiWindow />,
    render: <SideNote />,
  },
];
