import React, { useEffect } from "react";
import { useSelectedQuiz } from "../../Helpers/hooks";
import { useSelector } from "react-redux";
import Header from "./comp/Header";
import PreviewTypeSelector from "./comp/PreviewTypeSelector";
import Render from "./render/Render";

const Preview = () => {
  const quiz = useSelectedQuiz();

  return (
    <div className="flex flex-col w-screen h-screen bg-[#F8F8F8]">
      {quiz ? (
        <div className="flex w-full h-full flex-col">
          {" "}
          <Header />
          <div className="flex flex-col h-full px-24 pb-24  items-center justify-center">
            <div className="pt-6 pb-8">
              <PreviewTypeSelector />
            </div>
            <Render />
          </div>
        </div>
      ) : (
        <div className="flex w-screen h-screen items-center justify-center text-3xl ">
          Quiz not found
        </div>
      )}
    </div>
  );
};

export default Preview;
