import { BiLinkAlt } from "react-icons/bi";
import { BsLink45Deg } from "react-icons/bs";
import { HiOutlineChartPie } from "react-icons/hi";
import { MdDeleteOutline } from "react-icons/md";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { FaChartPie } from "react-icons/fa";
import { BiShareAlt } from "react-icons/bi";
import { AiOutlineFolderOpen } from "react-icons/ai";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteQuizApi } from "../../../APIs/quiz";
import { deleteQuiz } from "../../../State/Redux/features/quizzes/quizzesSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const actionMenuItems1 = [
  {
    id: "open",
    icon: <AiOutlineFolderOpen />,
    name: "Open",
    onClick: (data) => data.navigate(`/edit/${data.quizId}`),
  },
  {
    id: "copy_link",
    icon: <BiLinkAlt />,
    name: "Copy Link",
    onClick: (data) => {
      navigator.clipboard.writeText(
        `https://render.leadsbuddha.com/${data.quizId}`
      );
      toast.success("link copied");
    },
  },
  {
    id: "share",
    icon: <BiShareAlt />,
    name: "Share",
    onClick: (data) => data.navigate(`/share/${data.quizId}`),
  },
  {
    id: "result",
    icon: <HiOutlineChartPie />,
    name: "Result",
    onClick: (data) => data.navigate(`/analytics/${data.quizId}`),
  },
  {
    id: "duplicate",
    icon: <HiOutlineDocumentDuplicate />,
    name: "Duplicate",
    onClick: (data) => {},
  },
];

const ActionMenu = ({ quizId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleDeleteQuiz = async () => {
    try {
      const res = await deleteQuizApi(quizId);
      if (!res) return;
      dispatch(deleteQuiz(quizId));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="bg-white drop-shadow-md w-full flex flex-col p-2 rounded-lg gap-1">
      {actionMenuItems1.map((item, index) => (
        <button
          key={item.id}
          className="flex items-center text-[#1F204A] justify-start hover:bg-[#F6F7FA] rounded-lg p-1 gap-2"
          onClick={() =>
            item.onClick({
              navigate,
              quizId: quizId,
            })
          }
        >
          <div>{item.icon}</div>
          <div>{item.name}</div>
        </button>
      ))}
      <hr></hr>
      <button
        key={"delete"}
        className="flex items-center text-[#F54180] justify-start hover:bg-[#F6F7FA] rounded-lg p-1 gap-2"
        onClick={handleDeleteQuiz}
      >
        <div>
          <MdDeleteOutline />
        </div>
        <div>Delete</div>
      </button>
    </div>
  );
};

export default ActionMenu;
