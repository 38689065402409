import React, { useState } from "react";

const LeadGenForm = ({ question, index, setSelectedQueIndex }) => {
  const [answers, setAnswers] = useState(
    question.data.formBlocks.reduce((acc, que) => {
      const title = que.title;
      return { ...acc, [title]: "" };
    }, {})
  );
  return (
    <div className="flex flex-col items-start text-center justify-start">
      <div className="text-[#90919A] text-lg">Question {index}</div>
      <div className="text-[#1F204A] text-2xl font-normal pt-10 text-start">
        {question.data.title}
        {question.data?.toggleMenus?.filter(
          (menu) => menu.title === "Required"
        )?.[0]?.value === true && <span className="text-red-500 pl-1">*</span>}
      </div>

      <div className="py-2 pt-5 w-full flex flex-col gap-5">
        {question.data.formBlocks.map((block, index) => {
          return (
            <div className="flex flex-col gap-5 w-full" key={index}>
              {" "}
              <div className="text-[#90919A] text-base font-light text-start">
                {block.title ? block.title : ""}
              </div>
              <input
                className="w-full border-b-[2px] pb-3 border-[#E8EAEC] text-[#1F204A] text-xl font-light focus:outline-none"
                placeholder={block.placeholder}
                value={answers[block.title]}
                onChange={(e) => {
                  const newAnswers = JSON.parse(JSON.stringify(answers));
                  newAnswers[block.title] = e.target.value;
                  setAnswers(newAnswers);
                }}
              />
            </div>
          );
        })}
      </div>

      <div className="w-full flex items-start gap-1 pt-14">
        <button
          onClick={async () => {
            setSelectedQueIndex(index + 1);
          }}
          disabled={!answers || answers.length == 0}
          className="flex items-center justify-center text-base gap-2 px-6 py-2 rounded-md bg-[#2972C6] text-white text-center disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Submit
        </button>
        {question.data?.toggleMenus?.filter(
          (menu) => menu.title === "Required"
        )?.[0]?.value !== true && (
          <button
            onClick={async () => {
              setSelectedQueIndex(index + 1);
            }}
            className="flex items-center justify-center text-center text-base gap-2 px-4 py-2 "
          >
            Skip
          </button>
        )}
      </div>
    </div>
  );
};

export default LeadGenForm;
