import { BiLoader, BiTrash } from "react-icons/bi";
import { BiDuplicate } from "react-icons/bi";
import { IoDuplicateOutline } from "react-icons/io";
import { BiEditAlt } from "react-icons/bi";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { RxHamburgerMenu } from "react-icons/rx";
import React, { useState } from "react";
import {
  deleteQuestion,
  updateSelectedQuestion,
} from "../../../../State/Redux/features/quizzes/quizzesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteQuizApi } from "../../../../APIs/quiz";
import { deleteQuestionApi } from "../../../../APIs/question";

const ListMenu = ({
  handleDeleteQuestion,
  handleDuplicateQuestion,
  handleEditQuestion,
  question,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  return (
    <div className="w-fit flex p-3 relative">
      <button onClick={() => setShowDropDown(!showDropDown)}>
        <BiDotsHorizontalRounded />
      </button>
      {showDropDown && (
        <div className="absolute top-8 right-2 bg-white border-[1px] rounded-md p-2 shadow-lg min-w-[100px] z-50">
          <button
            onClick={() => {
              handleEditQuestion();
              setShowDropDown(false);
            }}
            className="w-full text-left hover:bg-[#F6F7FA] p-1 rounded-lg flex gap-2 items-center"
          >
            <BiEditAlt /> Edit
          </button>
          <button
            onClick={() => {
              handleDuplicateQuestion();
              setShowDropDown(false);
            }}
            disabled={
              question.value.queType === "welcome_screen" ||
              question.value.queType === "end_screen"
            }
            className="w-full text-left hover:bg-[#F6F7FA] p-1 rounded-lg flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <BiDuplicate />
            Duplicate
          </button>
          <button
            onClick={() => {
              handleDeleteQuestion();
              setShowDropDown(false);
            }}
            disabled={
              question.value.queType === "welcome_screen" ||
              question.value.queType === "end_screen"
            }
            className="w-full text-left hover:bg-[#F6F7FA] p-1 rounded-lg text-[#F54180] flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <BiTrash /> Delete
          </button>
        </div>
      )}
    </div>
  );
};

const ListUI = ({ question, provided }) => {
  const [showDeleteQuePopup, setShowDeleteQuePopup] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const handleDeleteQuestion = async () => {
    console.log(question);
    const res = await deleteQuestionApi(question.value.id);
    if (!res) return;
    dispatch(
      deleteQuestion({
        quizId: params.id,
        questionIndex: question.index,
      })
    );
  };

  const handleDuplicateQuestion = () => {
    console.log("duplicate");
  };

  const handleEditQuestion = () => {
    dispatch(
      updateSelectedQuestion({
        quizId: params.id,
        questionIndex: question.index,
      })
    );
  };

  return (
    <div className="w-full flex flex-row items-center bg-[#F8F8F8] border-[1px] broder-[#E8EAEC] justify-between gap-3 rounded-lg">
      <div className="flex items-center justify-start w-full gap-2 overflow-hidden text-ellipsis whitespace-nowrap">
        {question.value.queType === "welcome_screen" ||
        question.value.queType === "end_screen" ? (
          <div className="p-3 border-r-[1px] cursor-not-allowed">
            <RxHamburgerMenu />
          </div>
        ) : (
          <div className="p-3 border-r-[1px]" {...provided.dragHandleProps}>
            <RxHamburgerMenu />
          </div>
        )}

        <button
          onClick={() => {
            handleEditQuestion();
          }}
          className="w-full flex gap-2 items-center text-center"
        >
          <img src={question?.value?.icon} alt="icon" />
          <div>{question?.value?.data?.title}</div>
        </button>
      </div>

      <div className="flex items-center justify-center gap-2 px-2 text-lg">
        <button
          onClick={() => {
            handleDuplicateQuestion();
          }}
          disabled={
            question.value.queType === "welcome_screen" ||
            question.value.queType === "end_screen"
          }
          className="w-full text-left hover:bg-[#F6F7FA] p-1 rounded-lg flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <BiDuplicate />
        </button>
        <button
          onClick={() => {
            setShowDeleteQuePopup(true);
          }}
          disabled={
            question.value.queType === "welcome_screen" ||
            question.value.queType === "end_screen"
          }
          className="w-full text-left hover:bg-[#F6F7FA] p-1 rounded-lg text-[#F54180] flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <BiTrash />
        </button>
      </div>
      {showDeleteQuePopup ? (
        <div className="fixed top-[50%] right-[50%] translate-x-1/2 -translate-y-1/2 z-50 bg-black/40 p-12 flex items-center justify-center w-full h-full">
          <div className="flex flex-col gap-4 w-fit h-fit p-12 bg-white rounded-2xl drop-shadow-lg">
            <div className="flex w-full items-center justify-center text-lg font-semibold ">
              Confirm Delete
            </div>
            <div className="flex gap-4">
              <button
                disabled={isDeleting}
                onClick={async () => {
                  setIsDeleting(true);
                  await handleDeleteQuestion();
                  setShowDeleteQuePopup(false);
                  setIsDeleting(false);
                }}
                className="px-4 py-2 bg-red-400 text-white rounded-md disabled:opacity-50"
              >
                {isDeleting ? (
                  <div className="flex items-center justify-center gap-2">
                    <div className="animate-spin w-fit h-fit text-lg">
                      <BiLoader />
                    </div>
                    Deleting...
                  </div>
                ) : (
                  <div>Delete</div>
                )}
              </button>
              <button
                onClick={() => setShowDeleteQuePopup(false)}
                className="px-4 py-2 bg-gray-400 text-white rounded-md"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div></div>

      {/* <ListMenu
        question={question}
        handleDeleteQuestion={handleDeleteQuestion}
        handleDuplicateQuestion={handleDuplicateQuestion}
        handleEditQuestion={handleEditQuestion}
      /> */}
    </div>
  );
};

const RenderQueListItem = ({ question, provided }) => {
  return <ListUI question={question} provided={provided} />;
};

export default RenderQueListItem;
