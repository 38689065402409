import React, { useState } from "react";
import { toast } from "react-toastify";
import { userSignUpSchema } from "../../Helpers/validations";
import axios from "axios";
import envs from "../../Helpers/envs";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../State/Redux/features/user/userSlice";
import NavLogo from "../../App/comp/Navbar/comp/NavLogo";
import { registerApi } from "../../APIs/auth";
import { object } from "yup";
import { BiLoader } from "react-icons/bi";

const Signin = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async () => {
    try {
      setLoading(true);
      await userSignUpSchema.validate(
        { name, email, password, confirmPassword },
        { abortEarly: false }
      );

      const res = await registerApi({
        name,
        email,
        password,
      });

      if (res?.data?.success) {
        toast.success("Sign up successful");
        setTimeout(() => {
          //open signin page
          // navigate(`/verifyemail?email=${email}`);
          navigate(`/auth/signin`);
        }, 500);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.inner) {
        error.inner.forEach((e) => {
          toast.error(e.message);
        });
      } else if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Sign up failed");
      }
    }
  };
  return (
    <div className="flex w-full h-screen items-center justify-center">
      <div className="border border-[#E2E8F0] text-gray-700 text-lg bg-white p-14 rounded-xl flex flex-col items-center justify-center w-fit gap-4">
        <div className="flex w-full items-center justify-center">
          <NavLogo />
        </div>
        <div className="flex flex-col w-60">
          <input
            className="border border-gray-400 rounded-md p-2 mb-3 mt-3 w-full"
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
          <input
            className="border border-gray-400 rounded-md p-2 mb-3 w-full"
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <input
            className="border border-gray-400 rounded-md p-2 mb-3 w-full"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <input
            className="border border-gray-400 rounded-md p-2 mb-6 w-full"
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></input>
        </div>
        <button
          onClick={handleSignUp}
          className="border border-gray-400 rounded-md p-2 hover:bg-[#4A3AFF] hover:text-white px-8 font-semibold"
        >
          {loading ? (
            <div className="w-full h-full items-center justify-center text-center font-medium">
              <div className="flex items-center justify-center gap-2">
                <div className="animate-spin w-fit h-fit text-xl">
                  <BiLoader />
                </div>
                <div>Creating Account</div>
              </div>
            </div>
          ) : (
            <div>Sign Up</div>
          )}
        </button>
        <button
          className="text-sm font-bold text-gray-600 hover:text-[#4A3AFF]"
          onClick={() => {
            navigate("/auth/signin");
          }}
        >
          Have an account? Sign In
        </button>
      </div>
    </div>
  );
};

export default Signin;
