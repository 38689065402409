import React from "react";
import AddQuestion from "./comps/AddQuestion";
import QuestionsList from "./comps/QuestionsList";

const Create = () => {
  return (
    <div className="flex w-1/2 flex-col px-7 py-8 ">
      <div className="text-base font-medium pb-5">Create content</div>

      <QuestionsList />
      <AddQuestion />
    </div>
  );
};

export default Create;
