import { createSlice } from "@reduxjs/toolkit";
import {
  getPlatformUserState,
  setPlatformUserState,
} from "../../../../Helpers/platformState";

const initialState = getPlatformUserState();

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const user = action.payload;
      setPlatformUserState({ ...user, isLoggedIn: true });
      return user;
    },
    logout: (state) => {
      setPlatformUserState({});
      return {};
    },
    setUserFromPlatform: (state, action) => {
      const user = action.payload;
      return user;
    },
  },
});

export const { setUser, logout, setUserFromPlatform } = userSlice.actions;

export default userSlice.reducer;
