import React from "react";
import NavItems from "./comp/NavItems";
import NavLogoName from "./comp/NavLogoName";

const Navbar = () => {
  return (
    <div className="w-full h-full px-4 py-6 flex items-center justify-between bg-[#1C2536]">
      <NavLogoName />
      <NavItems />
    </div>
  );
};

export default Navbar;
