import { IoMdArrowDropdown } from "react-icons/io";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../../State/Redux/features/user/userSlice";
import { replaceQuizzes } from "../../../../State/Redux/features/quizzes/quizzesSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useOnClickOutsideRef } from "../../../../Helpers/hooks";

const NavItems = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [showDropDown, setShowDropDown] = useState(false);
  const modalRef = useOnClickOutsideRef(() => setShowDropDown(false));
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(replaceQuizzes([]));
    dispatch(logout());
  };
  return (
    <div
      ref={modalRef}
      className="flex relative flex-row items-center justify-between w-fit h-full text-[#8162EB] gap-4"
    >
      <button
        onClick={() => setShowDropDown(!showDropDown)}
        className="flex gap-2 items-center justify-center"
      >
        <div className="text-center bg-gray-200 text-black rounded-full border-[1px] text-xl h-8 w-8">
          {" "}
          {user?.name?.substring(0, 1)}{" "}
        </div>
        <div className="text-base text-white">{user.name}</div>
        <div className="text-gray-200">
          {" "}
          <IoMdArrowDropdown />
        </div>
      </button>

      {showDropDown && (
        <div className="absolute z-50 top-12 right-0 w-full p-2 gap-1 flex flex-col bg-white drop-shadow-md rounded-lg font-normal">
          <button
            className="flex w-full items-center text-[#1F204A] justify-start hover:bg-[#F6F7FA] rounded-lg p-1 gap-2"
            onClick={() => navigate("/settings")}
          >
            Settings
          </button>
          <button
            className="flex w-full items-center text-red-500 justify-start hover:bg-[#F6F7FA] rounded-lg p-1 gap-2"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default NavItems;
