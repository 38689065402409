import { MdNavigateNext } from "react-icons/md";
import { BiArrowBack } from "react-icons/bi";
import React from "react";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <div className="px-8 py-4 flex items-center justify-between w-full border-b-2 border-[#F8F8F8] bg-white shadow-md">
      <div className="flex gap-3 items-center justify-center text-base font-semibold text-[#1F204A]">
        <button
          onClick={() => {
            navigate(`/edit/${params.id}`);
          }}
        >
          <BiArrowBack />
        </button>
        <div>Share Quiz</div>
      </div>
      <div className="flex gap-2 items-center justify-center">
        <div className="flex gap-2 items-center justify-center">
          <div className="bg-[#34C38F] text-white rounded-full py-0 px-2">
            1
          </div>
          <button
            onClick={() => {
              navigate(`/edit/${params.id}`);
            }}
            className="text-[#1F204A] hover:text-[#BEC6D3]"
          >
            Build
          </button>
        </div>
        <MdNavigateNext />
        <div className="flex gap-2 items-center justify-center">
          <div className="bg-[#171055] text-white rounded-full py-0 px-2">
            2
          </div>
          <div className="text-[#1F204A]">Share</div>
        </div>
      </div>
      <div className="flex gap-3 items-center justify-center">
        <a
          href={"/preview/" + params.id}
          target="_blank"
          rel="noreferrer"
          className="bg-[#F8F8F8] rounded-md flex items-center justify-center
          gap-1 text-[#171055] font-medium text-base px-6 py-2"
        >
          <AiOutlineEye /> Preview
        </a>
      </div>
    </div>
  );
};

export default Header;
