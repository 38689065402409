import React, { useState } from "react";
import Navbar from "../../App/comp/Navbar/Navbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteAccountApi, updatePassApi } from "../../APIs/auth";
import { toast } from "react-toastify";
import { updatePassSchema } from "../../Helpers/validations";
import { BiLoader } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { replaceQuizzes } from "../../State/Redux/features/quizzes/quizzesSlice";
import { logout } from "../../State/Redux/features/user/userSlice";

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [updateUsername, setUpdateUsername] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [updatePass, setUpdatePass] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [showDeleteAccountPopup, setShowDeleteQuePopup] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handlePasswordUpdate = async () => {
    try {
      await updatePassSchema.validate(
        { oldPass, newPass },
        { abortEarly: false }
      );

      const res = await updatePassApi({
        old_password: oldPass,
        new_password: newPass,
      });
      if (res) {
        toast.success("Password Updated");
      } else {
        toast.error("Failed To Update Password");
      }
    } catch (error) {
      if (error.inner) {
        error.inner.forEach((e) => {
          toast.error(e.message);
        });
      } else if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Failed to update Password");
      }
    }
  };
  const handleUserNameUpdate = async () => {
    try {
      await updatePassSchema.validate(
        { oldPass, newPass },
        { abortEarly: false }
      );

      const res = await updatePassApi({
        old_password: oldPass,
        new_password: newPass,
      });
      if (res) {
        toast.success("Password Updated");
      } else {
        toast.error("Failed To Update Password");
      }
    } catch (error) {
      toast.error("Failed To Update Password");
    }
  };
  const handleDeleteAccount = async () => {
    try {
      const res = await deleteAccountApi();
      if (res) {
        toast.success("Account Deleted");
        dispatch(replaceQuizzes([]));
        dispatch(logout());
      } else {
        toast.error("Failed To Delete Account");
      }
    } catch (error) {
      toast.error("Failed To Delete Account");
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-white ">
      <Navbar />
      <div className="flex flex-col gap-12 w-full h-full p-24 items-start justify-start">
        <div className="flex flex-col gap-6">
          <div className="text-xl font-semibold">User Settings</div>
          <div className="flex flex-col gap-2">
            <div className="text-lg font-medium opacity-50">Email</div>
            <div className="text-lg font-medium">{user.email}</div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-lg font-medium opacity-50">Name</div>

            {!updateUsername ? (
              <div className="flex gap-6">
                <div className="text-lg font-medium">{user.name}</div>
                <button
                  className="bg-[#4A3AFF] text-white px-4 py-1 rounded-md hover:opacity-100 opacity-75"
                  onClick={() => setUpdateUsername(true)}
                >
                  update
                </button>
              </div>
            ) : (
              <div className="flex gap-6">
                <input
                  className="w-full flex items-center justify-center border border-[#1F204A]/10 px-2 text-[#1F204A] py-1 font-medium rounded-lg"
                  placeholder="Change Name"
                  value={newUsername}
                  onChange={(e) => setNewUsername(e.target.value)}
                />
                <div className="flex gap-2">
                  <button
                    className="bg-[#4A3AFF] text-white px-4 py-1 rounded-md hover:opacity-100 opacity-75"
                    onClick={handleUserNameUpdate}
                  >
                    save
                  </button>
                  <button
                    className="bg-yellow-600 text-white px-4 py-1 rounded-md hover:opacity-100 opacity-75"
                    onClick={() => setUpdateUsername(false)}
                  >
                    close
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-lg font-medium opacity-50">Password</div>

            {!updatePass ? (
              <div className="flex gap-6">
                <button
                  className="bg-[#4A3AFF] text-white px-4 py-1 rounded-md hover:opacity-100 opacity-75"
                  onClick={() => setUpdatePass(true)}
                >
                  update
                </button>
              </div>
            ) : (
              <div className="flex gap-6">
                <input
                  className="w-full flex items-center justify-center border border-[#1F204A]/10 px-2 text-[#1F204A] py-1 font-medium rounded-lg"
                  type="password"
                  placeholder="Old Password"
                  value={oldPass}
                  onChange={(e) => setOldPass(e.target.value)}
                />
                <input
                  className="w-full flex items-center justify-center border border-[#1F204A]/10 px-2 text-[#1F204A] py-1 font-medium rounded-lg"
                  type="password"
                  placeholder="New Password"
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                />
                <div className="flex gap-2">
                  <button
                    className="bg-[#4A3AFF] text-white px-4 py-1 rounded-md hover:opacity-100 opacity-75"
                    onClick={handlePasswordUpdate}
                  >
                    save
                  </button>
                  <button
                    className="bg-yellow-600 text-white px-4 py-1 rounded-md hover:opacity-100 opacity-75"
                    onClick={() => {
                      setUpdatePass(false);
                      setOldPass("");
                      setNewPass("");
                    }}
                  >
                    close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="text-xl font-semibold">Account Settings</div>

          <div className="flex flex-col gap-2">
            <div className="text-lg font-medium opacity-50">
              Subscribed Plan
            </div>
            <div className="flex gap-6">
              <div className="text-lg font-medium">
                {user?.subscription?.plan || "Free Trial"}
              </div>
              {/* <button
                onClick={() => {
                  // redirect to pricing page
                  navigate("/pricing");
                }}
              >
                Change
              </button> */}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <button
              className="bg-red-600 text-white px-4 py-1 rounded-md hover:opacity-100 opacity-75"
              onClick={() => {
                setShowDeleteQuePopup(true);
              }}
            >
              Delete Account
            </button>
          </div>
        </div>
        {showDeleteAccountPopup ? (
          <div className="fixed top-[50%] right-[50%] translate-x-1/2 -translate-y-1/2 z-50 bg-black/40 p-12 flex items-center justify-center w-full h-full">
            <div className="flex flex-col gap-4 w-fit h-fit p-12 bg-white rounded-2xl drop-shadow-lg">
              <div className="flex w-full items-center justify-center text-lg font-semibold ">
                Confirm Account Delete
              </div>
              <div className="flex gap-4 items-center justify-center">
                <button
                  disabled={isDeleting}
                  onClick={async () => {
                    setIsDeleting(true);
                    await handleDeleteAccount();
                    setShowDeleteQuePopup(false);
                    setIsDeleting(false);
                  }}
                  className="px-4 py-2 bg-red-600 text-white rounded-md disabled:opacity-50"
                >
                  {isDeleting ? (
                    <div className="flex items-center justify-center gap-2">
                      <div className="animate-spin w-fit h-fit text-lg">
                        <BiLoader />
                      </div>
                      Deleting...
                    </div>
                  ) : (
                    <div>Delete</div>
                  )}
                </button>
                <button
                  onClick={() => setShowDeleteQuePopup(false)}
                  className="px-4 py-2 bg-gray-400 text-white rounded-md"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Settings;
