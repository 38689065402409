import React from "react";

const Rating = ({ question, index }) => {
  return (
    <div className="flex flex-col items-start text-center justify-start">
      <div className="text-[#90919A] text-lg">Question {index}</div>
      <div className="text-[#1F204A] text-2xl font-normal pt-10 text-start">
        {question.data.title}
        {question.data?.toggleMenus?.filter(
          (menu) => menu.title === "Required"
        )?.[0]?.value === true && <span className="text-red-500 pl-1">*</span>}
      </div>
      <div className="text-[#1F204A] flex w-full gap-2 items-center justify-start text-base font-normal pt-8 flex-wrap">
        {Array.from(Array(parseInt(question.data.max_rating)).keys()).map(
          (i) => {
            return (
              <div
                key={i}
                style={
                  i >= 9
                    ? {
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                      }
                    : {}
                }
                className="flex items-center border-[1px] border-[#E8EAEC] bg-[#F6F7FA] rounded-full py-3 px-5"
              >
                {i + 1}
              </div>
            );
          }
        )}
      </div>
      <div className="text-[#90919A] flex w-full gap-2 items-center justify-between text-sm font-normal pt-4">
        <div>Least Likely</div>
        <div>Most Likely</div>
      </div>
      <div className="w-full flex items-start gap-1 pt-14">
        <button className="flex items-center justify-center text-base gap-2 px-6 py-2 rounded-md bg-[#2972C6] text-white text-center">
          Submit
        </button>
        {question.data?.toggleMenus?.filter(
          (menu) => menu.title === "Required"
        )?.[0]?.value !== true && (
          <button className="flex items-center justify-center text-center text-base gap-2 px-4 py-2 ">
            Skip
          </button>
        )}
      </div>
    </div>
  );
};

export default Rating;
