import React, { useState } from "react";
import { BiLoader } from "react-icons/bi";
import NavLogo from "../../App/comp/Navbar/comp/NavLogo";
import { toast } from "react-toastify";
import { forgotPassApi } from "../../APIs/auth";
import { forgotPassSchema } from "../../Helpers/validations";
import { useNavigate } from "react-router-dom";

const ForgotPass = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleSignIn = async () => {
    try {
      setLoading(true);
      await forgotPassSchema.validate({ email }, { abortEarly: false });

      const res = await forgotPassApi({
        email: email,
      });
      if (res) {
        navigate("/auth/signin");
        toast.success("Link Sent Successfully");
      } else {
        toast.error("Failed to send Link");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.inner) {
        error.inner.forEach((e) => {
          toast.error(e.message);
        });
      } else if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Failed to send Link");
      }
    }
  };
  return (
    <div className="flex w-full h-screen items-center justify-center">
      <div className="border border-[#E2E8F0] text-gray-700 text-lg bg-white p-14 rounded-xl  flex flex-col items-center justify-center gap-4">
        <div className="flex w-full items-center justify-center">
          <NavLogo />
        </div>
        <div className="flex flex-col w-60">
          <input
            className="border border-gray-400 rounded-md p-2 mb-3 mt-3 w-full"
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <button
          onClick={handleSignIn}
          className="border border-gray-400 rounded-md p-2 hover:bg-[#4A3AFF] hover:text-white px-8 font-semibold"
        >
          {loading ? (
            <div className="w-full h-full items-center justify-center text-center font-medium">
              <div className="flex items-center justify-center gap-2">
                <div className="animate-spin w-fit h-fit text-xl">
                  <BiLoader />
                </div>
                <div>Sending Link...</div>
              </div>
            </div>
          ) : (
            <div>Send Link</div>
          )}
        </button>
        <div className="flex flex-col gap-1">
          <button
            className="text-sm font-bold text-gray-600 hover:text-[#4A3AFF]"
            onClick={() => {
              navigate("/auth/signin");
            }}
          >
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPass;
