import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import WelcomeScreen from "./comps/WelcomeScreen";
import EndScreen from "./comps/EndScreen";
import MultipleChoice from "./comps/MultipleChoice";
import Rating from "./comps/Rating";
import Text from "./comps/Text";
import TrueFalse from "./comps/TrueFalse";
import LeadGenForm from "./comps/LeadGenForm";

const RenderSelectedQueView = ({
  question,
  index,
  setSelectedQueIndex,
  questionsLength,
}) => {
  switch (question?.queType) {
    case "welcome_screen":
      return (
        <WelcomeScreen
          question={question}
          index={index}
          setSelectedQueIndex={setSelectedQueIndex}
          questionsLength={questionsLength}
        />
      );
    case "end_screen":
      return (
        <EndScreen
          question={question}
          index={index}
          setSelectedQueIndex={setSelectedQueIndex}
        />
      );
    case "text":
      return (
        <Text
          question={question}
          index={index}
          setSelectedQueIndex={setSelectedQueIndex}
        />
      );
    case "multiple_choice":
      return (
        <MultipleChoice
          question={question}
          index={index}
          setSelectedQueIndex={setSelectedQueIndex}
        />
      );
    case "rating":
      return (
        <Rating
          question={question}
          index={index}
          setSelectedQueIndex={setSelectedQueIndex}
        />
      );
    case "true_false":
      return (
        <TrueFalse
          question={question}
          index={index}
          setSelectedQueIndex={setSelectedQueIndex}
        />
      );
    case "form":
      return (
        <LeadGenForm
          question={question}
          index={index}
          setSelectedQueIndex={setSelectedQueIndex}
        />
      );
    default:
      return <div>failed</div>;
  }
};

const Render = () => {
  const params = useParams();
  const quizzes = useSelector((state) => state.quizzes).list;
  const previewType = useSelector((state) => state.settings).previewType;

  const [quiz, setQuiz] = useState(
    quizzes.filter((quiz) => quiz.id === params.id)[0]
  );
  const [selectedQueIndex, setSelectedQueIndex] = useState(0);

  useEffect(() => {
    setQuiz(quizzes.filter((quiz) => quiz.id === params.id)[0]);
    console.log(quiz);
  }, [quizzes, params.id]);

  return (
    <div
      style={
        previewType === "desktop"
          ? {
              width: "100%",
            }
          : {
              width: "30%",
            }
      }
      className="h-full preview-que-shadow bg-white rounded-2xl flex items-center justify-center p-10 relative"
    >
      <div
        // style={
        //   selectedQueIndex === 0
        //     ? {
        //         display: "none",
        //       }
        //     : {}
        // }
        className="absolute top-10 right-10 gap-4 flex"
      >
        <button
          disabled={selectedQueIndex === 0}
          onClick={() => setSelectedQueIndex((prevState) => prevState - 1)}
          className="h-8 w-8 rounded-xl border-[1px] border-[#171055] bg-[#1F204A]/5 text-[#171055] text-center flex items-center justify-center text-2xl disabled:opacity-30 disabled:cursor-not-allowed"
        >
          <BiChevronUp />
        </button>
        <button
          disabled={selectedQueIndex === quiz.questions.length - 1}
          onClick={() => setSelectedQueIndex((prevState) => prevState + 1)}
          className="h-8 w-8 rounded-xl border-[1px] border-[#171055] bg-[#1F204A]/5 text-[#171055] text-center flex items-center justify-center text-2xl disabled:opacity-30 disabled:cursor-not-allowed"
        >
          <BiChevronDown />
        </button>
      </div>
      {quiz && quiz.questions[selectedQueIndex] && (
        <RenderSelectedQueView
          questionsLength={quiz.questions.length}
          question={quiz.questions[selectedQueIndex]}
          index={selectedQueIndex}
          setSelectedQueIndex={setSelectedQueIndex}
        />
      )}
      <div
        style={
          selectedQueIndex === 0
            ? {
                display: "none",
              }
            : {}
        }
        className="absolute top-10 right-10 gap-4 flex"
      ></div>
    </div>
  );
};

export default Render;
