import welcomeIcon from "../assets/images/question/welcome.svg";
import endIcon from "../assets/images/question/end.svg";
import mcqIcon from "../assets/images/question/mcq.svg";
import ratingIcon from "../assets/images/question/rating.svg";
import textIcon from "../assets/images/question/text.svg";
import trueFalseIcon from "../assets/images/question/true_false.svg";
import formIcon from "../assets/images/question/form.svg";

import customIcon from "../assets/images/form/custom.svg";
import emailIcon from "../assets/images/form/email.svg";
import nameIcon from "../assets/images/form/name.svg";
import phoneIcon from "../assets/images/form/phone.svg";
import urlIcon from "../assets/images/form/url.svg";

export const questionList = [
  {
    queType: "welcome_screen",
    queName: "Welcome Screen",
    type: "Quiz Structure",
    max: 1,
    icon: welcomeIcon,
    data: {
      title: "Hello! Let's get started! Mention what this quiz is about.",
    },
  },
  {
    queType: "end_screen",
    queName: "End Screen",
    type: "Quiz Structure",
    max: 1,
    icon: endIcon,
    data: {
      title: "Bye! Thank you for taking the survey. We appreciate your input!",
    },
  },
  {
    queType: "rating",
    queName: "Rating",
    type: "Question Type",
    max: 99,
    icon: ratingIcon,
    data: {
      max_rating: 10,
      title: "How would you rate this quiz?",
      toggleMenus: [
        {
          title: "Required",
          label: "is_required",
          value: false,
        },
      ],
    },
  },
  {
    queType: "multiple_choice",
    queName: "Multiple Choice",
    type: "Question Type",
    max: 99,
    icon: mcqIcon,
    data: {
      title: "Type your question",
      options: ["Option 1", "Option 2"],
      toggleMenus: [
        {
          title: "Multiple selection",
          label: "is_multiple_choice",
          value: false,
        },
        {
          title: "Required",
          label: "is_required",
          value: false,
        },
      ],
    },
  },
  {
    queType: "true_false",
    queName: "True or False",
    type: "Question Type",
    max: 99,
    icon: trueFalseIcon,
    data: {
      title: "Is this a true or false question?",
      toggleMenus: [
        {
          title: "Required",
          label: "is_required",
          value: false,
        },
      ],
    },
  },
  {
    queType: "text",
    queName: "Short Answer",
    type: "Question Type",
    max: 99,
    icon: textIcon,
    data: {
      title: "Title",
      desc: "",
      toggleMenus: [
        {
          title: "Required",
          label: "is_required",
          value: false,
        },
      ],
    },
  },
  {
    queType: "form",
    queName: "Lead generation form",
    type: "Form",
    max: 99,
    icon: formIcon,
    formBlocks: [
      {
        id: "name",
        name: "Name",
        title: "name",
        placeholder: "Enter your name here",
        icon: nameIcon,
      },
      {
        id: "email",
        name: "Email",
        title: "Email address",
        placeholder: "Enter your email here",
        icon: emailIcon,
      },
      {
        id: "phone",
        name: "Phone",
        title: "Phone number",
        placeholder: "Enter your phone number with zipcode",
        icon: phoneIcon,
      },
      {
        id: "url",
        name: "URL",
        title: "URL",
        placeholder: "https://example.com",
        icon: urlIcon,
      },
      {
        id: "custom",
        name: "Custom",
        title: "Enter your title here",
        placeholder: "Enter your placeholder text here",
        icon: customIcon,
      },
    ],
    data: {
      title: "Form Title",
      toggleMenus: [
        {
          title: "Required",
          label: "is_required",
          value: true,
        },
      ],
      formBlocks: [
        {
          id: "custom",
          name: "Custom",
          title: "Enter your title here",
          placeholder: "Enter your placeholder text here",
          icon: customIcon,
        },
      ],
    },
  },
];

export const questionTypeList = questionList.reduce((acc, question) => {
  const { type, ...rest } = question;
  const existingType = acc.find((item) => item.type === type);

  if (existingType) {
    existingType.questions.push(rest);
  } else {
    acc.push({
      type,
      questions: [rest],
    });
  }

  return acc;
}, []);

export const getQuestionsFormatForQueId = (queType) => {
  const question = questionList.find((item) => item.queType === queType);
  return question;
};

export const getQueIconForQueType = (queType) => {
  const que = questionList.find((item) => item.queType === queType);
  return que.icon;
};
