import { updateQuestionsApi } from "../APIs/question";

export const indexToAlphabet = (index) => {
  return String.fromCharCode(65 + index);
};
export const convertISOtoTimestamp = (ISOstring) => {
  const originalDate = new Date(ISOstring);
  const modifiedOnTimestamp = originalDate.getTime();

  return modifiedOnTimestamp;
};

export const updateQuestion = async (newQuestion, quizId) => {
  const res = await updateQuestionsApi(quizId, newQuestion);
  return res;
};

export const deleteQuestionStateReorder = (deleteQueOrder, questionsList) => {
  console.log(questionsList, deleteQueOrder);
  const newQuestionsList = questionsList.map((que, index) => {
    if (que.order > deleteQueOrder) {
      que.order = que.order - 1;
    }
    return que;
  });

  return newQuestionsList;
};
