import { MdSort } from "react-icons/md";
import React, { useEffect, useState } from "react";
import Navbar from "../../App/comp/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import FirstFormLanding from "./comp/FirstFormLanding";
import CreateQuizPopup from "./comp/CreateQuizPopup";
import QuizCard from "./comp/QuizCard";
import axios from "axios";
import { getQuizzesApi } from "../../APIs/quiz";
import { quizListApiToReduxQuizList } from "../../Helpers/ReduxApiBridge";
import {
  replaceQuizzes,
  sortQuizzes,
} from "../../State/Redux/features/quizzes/quizzesSlice";
import { BiLoader, BiSearch } from "react-icons/bi";
import { useOnClickOutsideRef } from "../../Helpers/hooks";

const Home = () => {
  const dispatch = useDispatch();
  const quizzes = useSelector((state) => state.quizzes).list;
  const [loading, setLoading] = useState(true);
  const [showSortByDropDown, setShowSortByDropDown] = useState(false);
  const modalRef = useOnClickOutsideRef(() => setShowSortByDropDown(false));
  const [filteredQuizzes, setFilteredQuizzes] = useState(
    useSelector((state) => state.quizzes).list
  );
  const [searchText, setSearchText] = useState("");

  const fetchAndSetQuizes = async () => {
    setLoading(true);
    const res = await getQuizzesApi();
    console.log(res.data);
    const newQuizList = quizListApiToReduxQuizList(res.data);
    dispatch(replaceQuizzes(newQuizList));
    setLoading(false);
  };

  useEffect(() => {
    fetchAndSetQuizes();
  }, []);

  useEffect(() => {
    setFilteredQuizzes(quizzes);
  }, [quizzes]);

  const [showCreateQuizPopup, setshowCreateQuizPopup] = useState(false);

  const closePopup = () => {
    setshowCreateQuizPopup(false);
  };

  const openPopup = () => {
    setshowCreateQuizPopup(true);
  };

  const handleQuizzesSort = (sortType) => {
    dispatch(
      sortQuizzes({
        sortType,
      })
    );
  };

  return (
    <div className="flex flex-col w-full h-full bg-white ">
      <Navbar />
      {loading ? (
        <div className="w-full h-full items-center justify-center text-center py-48">
          <div className="flex items-center justify-center gap-2">
            <div className="animate-spin w-fit h-fit text-3xl">
              <BiLoader />
            </div>
          </div>
        </div>
      ) : quizzes.length === 0 ? (
        <FirstFormLanding openPopup={openPopup} />
      ) : (
        <div className="flex flex-col w-full h-full p-8">
          <div className="flex items-center justify-between w-full pb-8">
            <div className="text-3xl font-semibold text-[#1F204A]">
              Your workspace
            </div>
            <button
              onClick={openPopup}
              className="px-5 py-3 rounded-xl bg-[#4A3AFF] text-white text-sm"
            >
              Create New
            </button>
          </div>
          <div>
            <div className="pb-5 flex items-center justify-between border-b-[1px] border-[#EFF0F6]">
              <div className="text-2xl font-semibold text-[#1F204A]">
                All quizzes ({quizzes.length})
              </div>
              <div className="flex gap-4 font-semibold text-[#1F204A]">
                <div className="relative pb-2">
                  <div className="absolute top-1/2 -translate-y-1/2 left-3 pb-2">
                    <BiSearch />
                  </div>
                  <input
                    className="w-full pl-8 flex items-center justify-center border border-[#1F204A]/10 bg-[#1F204A]/5 text-[#1F204A] py-1 font-medium rounded-lg"
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      setFilteredQuizzes(() =>
                        quizzes.filter((quiz) =>
                          quiz.name.toLowerCase().includes(e.target.value)
                        )
                      );
                    }}
                    placeholder="Search Quiz"
                  ></input>
                </div>
                <div className="relative" ref={modalRef}>
                  <button
                    onClick={() => setShowSortByDropDown(!showSortByDropDown)}
                    className="w-full px-2 py-1 flex items-center justify-center border border-[#1F204A]/10 text-[#1F204A]/70 font-medium rounded-lg"
                    type="text"
                  >
                    <MdSort /> <p className="px-1">Sort by</p>
                  </button>
                  {showSortByDropDown && (
                    <div className="absolute z-50 top-12 right-0 w-32 p-2 gap-1 flex flex-col bg-white drop-shadow-md rounded-lg font-normal">
                      <button
                        className="flex w-full items-center text-[#1F204A] justify-start hover:bg-[#F6F7FA] rounded-lg p-1 gap-2"
                        onClick={() => handleQuizzesSort("name-asc")}
                      >
                        Name - Asc
                      </button>
                      <button
                        className="flex w-full items-center text-[#1F204A] justify-start hover:bg-[#F6F7FA] rounded-lg p-1 gap-2"
                        onClick={() => handleQuizzesSort("name-desc")}
                      >
                        Name - Desc
                      </button>
                      <button
                        className="flex w-full items-center text-[#1F204A] justify-start hover:bg-[#F6F7FA] rounded-lg p-1 gap-2"
                        onClick={() => handleQuizzesSort("date-asc")}
                      >
                        Date - Asc
                      </button>
                      <button
                        className="flex w-full items-center text-[#1F204A] justify-start hover:bg-[#F6F7FA] rounded-lg p-1 gap-2"
                        onClick={() => handleQuizzesSort("date-desc")}
                      >
                        Date - Desc
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full pt-7">
              <table
                style={{
                  borderCollapse: "separate",
                  borderSpacing: "0 20px",
                }}
                className="table-auto w-full"
              >
                <thead>
                  <tr>
                    <th className="text-start text-[#90919A] text-sm font-medium">
                      QUIZ NAME
                    </th>
                    <th className="text-center text-[#90919A] text-sm font-medium">
                      VIEWS
                    </th>
                    <th className="text-center text-[#90919A] text-sm font-medium">
                      STARTS
                    </th>
                    <th className="text-center text-[#90919A] text-sm font-medium">
                      SUBMISSION
                    </th>
                    <th className="text-center text-[#90919A] text-sm font-medium">
                      COMPLETION RATE
                    </th>
                    <th className="text-center text-[#90919A] text-sm font-medium">
                      STATUS
                    </th>
                    <th className="text-center text-[#90919A] text-sm font-medium">
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredQuizzes.map((quiz) => (
                    <QuizCard key={quiz.id} quiz={quiz} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {showCreateQuizPopup && <CreateQuizPopup closePopup={closePopup} />}
    </div>
  );
};

export default Home;
