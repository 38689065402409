import { MdDone } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { AiOutlineEdit } from "react-icons/ai";
import { MdNavigateNext } from "react-icons/md";
import { BiArrowBack, BiLoader } from "react-icons/bi";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateQuizApi } from "../../../APIs/quiz";
import { updateQuiz } from "../../../State/Redux/features/quizzes/quizzesSlice";
import { toast } from "react-toastify";
import { updateQuestion } from "../../../Helpers/utils";

const Header = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quizzes = useSelector((state) => state.quizzes).list;
  const quiz = quizzes.filter((quiz) => quiz.id === params.id)[0];
  const [isPublishing, SetIsPublishing] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  const [newName, setNewName] = useState(quiz.name);
  const [isQuizSaving, setIsQuizSaving] = useState(false);

  const handlePublish = async () => {
    SetIsPublishing(true);
    const res = await updateQuizApi(params.id, {
      status: "published",
    });
    if (!res) {
      SetIsPublishing(false);
      return;
    }
    dispatch(
      updateQuiz({
        quizId: params.id,
        newData: {
          status: "published",
        },
      })
    );
    if (!res) {
      SetIsPublishing(false);
      return;
    }
    SetIsPublishing(false);
    navigate("/share/" + params.id);
  };

  const handleEditName = async (newName) => {
    const res = await updateQuizApi(params.id, {
      name: newName,
    });
    if (!res) {
      toast.error("Failed To Update Name");
    }
    dispatch(
      updateQuiz({
        quizId: params.id,
        newData: {
          name: newName,
        },
      })
    );
    setIsEditName(false);
    toast.success("Name Updated");
  };

  const handleSaveAndExit = async () => {
    try {
      console.log(quiz.questions);
      setIsQuizSaving(true);
      for (const question of quiz.questions) {
        const res = await updateQuestion(question, params.id);
      }

      navigate(`/`);
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
  };
  return (
    <div className="px-8 py-4 flex items-center justify-between w-full border-b-2 border-[#F8F8F8]">
      <div className="flex gap-3 items-center justify-center text-base font-semibold text-[#1F204A]">
        <button
          onClick={() => {
            navigate(`/`);
          }}
        >
          <BiArrowBack />
        </button>
        {isEditName ? (
          <>
            <input
              type="text"
              value={newName}
              placeholder="Quiz Name"
              onChange={(e) => setNewName(e.target.value)}
            />
            <button
              onClick={() => handleEditName(newName)}
              className="bg-[#1F204A]/5 p-1 rounded"
            >
              <MdDone />
            </button>
            <button
              onClick={() => setIsEditName(false)}
              className="bg-[#1F204A]/5 p-1 rounded"
            >
              <IoMdClose />
            </button>
          </>
        ) : (
          <>
            <div>{quiz.name}</div>
            <button
              onClick={() => setIsEditName(true)}
              className="bg-[#1F204A]/5 p-1 rounded"
            >
              <AiOutlineEdit />
            </button>
          </>
        )}
      </div>
      <div className="flex gap-2 items-center justify-center">
        <div className="flex gap-2 items-center justify-center">
          <div className="bg-[#171055] text-white rounded-full py-0 px-2">
            1
          </div>
          <div className="text-[#1F204A]">Build</div>
        </div>
        <MdNavigateNext />
        <div className="flex gap-2 items-center justify-center">
          <div className="bg-[#F6F7FA] text-[#1F204A] rounded-full py-0 px-2">
            2
          </div>
          <button
            onClick={() => {
              if (quiz.status === "draft") {
                toast.error("Please Publish Quiz To Share!");
                return;
              }
              navigate(`/share/${params.id}`);
            }}
            className="text-[#BEC6D3] hover:text-[#1F204A]"
          >
            Share
          </button>
        </div>
      </div>
      <div className="flex gap-3 items-center justify-center">
        <button
          onClick={handleSaveAndExit}
          className="bg-[#F8F8F8] rounded-md text-[#171055] font-medium text-base px-6 py-2"
        >
          {isQuizSaving ? "Saving..." : "Save & Exit"}
        </button>
        {isPublishing ? (
          <button
            onClick={handlePublish}
            disabled={true}
            className="bg-[#4A3AFF] rounded-md text-white font-medium text-base px-6 py-2 opacity-50"
          >
            <div className="flex items-center justify-center gap-2">
              <div className="animate-spin w-fit h-fit text-lg">
                <BiLoader />
              </div>
              Publishing...
            </div>
          </button>
        ) : quiz.status === "published" ? (
          <button
            disabled={true}
            className="bg-[#4A3AFF] rounded-md text-white font-medium text-base px-6 py-2 opacity-50"
          >
            Published
          </button>
        ) : (
          <button
            onClick={handlePublish}
            className="bg-[#4A3AFF] rounded-md text-white font-medium text-base px-6 py-2"
          >
            Publish
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
