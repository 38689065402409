import { BiChevronDown } from "react-icons/bi";
import React, { useState } from "react";
import { Cell, Pie, PieChart } from "recharts";
const dropDownItemsFirst = ["views", "starts", "completion"];
const dropDownItemsSecond = ["starts", "completion", "leads"];

const DropDown = ({ name, type, handleUpdateValue }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const getDropdownItems = (type) => {
    if (type === "first") return dropDownItemsFirst;
    if (type === "second") return dropDownItemsSecond;
    return [];
  };
  return (
    <div className="relative">
      <button
        onClick={() => setShowDropDown(!showDropDown)}
        className="px-2 py-1 bg-[#1F204A]/5 flex gap-1 items-center justify-between rounded-md text-[#1F204A]"
      >
        <div className="px-2 capitalize">{name}</div>
        <div>
          <BiChevronDown />
        </div>
      </button>
      {showDropDown && (
        <div
          // onBlur={() => {
          //   setShowDropDown(false);
          // }}
          className="absolute top-10 -left-0 bg-[#1F204A]/10 p-2 rounded-md flex flex-col gap-1"
        >
          {getDropdownItems(type).map((item) => {
            return (
              <button
                onClick={() => {
                  handleUpdateValue(item);
                  setShowDropDown(false);
                }}
                className="text-start hover:bg-[#1F204A]/5 p-1 rounded-sm capitalize"
              >
                {" "}
                {item}{" "}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

const ConversionRate = ({ quiz, totalLeads }) => {
  const dropdownData = {
    views: quiz.data.quiz_views,
    starts: quiz.data.starts,
    completion: "1",
    leads: totalLeads,
  };
  const [selectedFirstDropdown, setSelectedFirstDropdown] = useState("views");
  const [selectedSecondDropdown, setSelectedSecondDropdown] =
    useState("completion");
  const data = [
    { name: "converted", value: dropdownData[selectedFirstDropdown] },
    { name: "remaining", value: dropdownData[selectedSecondDropdown] },
  ];
  const COLORS = ["#6366F1", "#F5F7FF"];
  return (
    <div
      className="flex flex-col w-[40%] rounded-3xl p-5"
      style={{ boxShadow: "0px 5px 22px 0px #0000000A" }}
    >
      <div className="text-[#111927] text-xl font-medium pb-4">
        Conversion Rate
      </div>
      <div className="flex flex-col items-center justify-start w-full h-fit gap-2">
        <PieChart
          width={400}
          height={200}
          className=" flex items-center justify-center"
        >
          <Pie
            data={data}
            startAngle={180}
            endAngle={0}
            innerRadius={60}
            outerRadius={80}
            fill="#6366F1"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>{" "}
          <text
            x={200}
            y={80}
            dy={8}
            textAnchor="middle"
            fill="#1F204A"
            fontSize="1.225rem"
          >
            {(
              (100 * dropdownData[selectedSecondDropdown]) /
              dropdownData[selectedFirstDropdown]
            ).toFixed(0)}
            %
          </text>
          <text
            x={200}
            y={150}
            textAnchor="middle"
            fill="#1F204A"
            className="flex flex-wrap font-medium text-base"
          >
            You’ve only reached{" "}
            {(
              (100 * dropdownData[selectedSecondDropdown]) /
              dropdownData[selectedFirstDropdown]
            ).toFixed(0)}
            % conversion from
          </text>
          <text
            x={200}
            y={172}
            textAnchor="middle"
            fill="#1F204A"
            className="flex flex-wrap font-medium text-base"
          >
            Business survey quiz
          </text>
        </PieChart>
      </div>
      <hr
        style={{ color: "#F2F4F7", background: "#F2F4F7" }}
        className="opacity-50"
      />
      <div className="pt-8 flex w-full items-center justify-center gap-4">
        <DropDown
          name={selectedFirstDropdown}
          type={"first"}
          handleUpdateValue={setSelectedFirstDropdown}
        />
        <div>Converts on</div>
        <DropDown
          name={selectedSecondDropdown}
          type={"second"}
          handleUpdateValue={setSelectedSecondDropdown}
        />
      </div>
    </div>
  );
};

export default ConversionRate;
