import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <div className="px-8 py-4 flex items-center justify-between w-full shadow-md z-50 ">
      <div className="flex gap-3 items-center justify-center text-base font-medium text-[#1F204A]">
        {/* <button
          onClick={() => {
            navigate(`/share/${params.id}`);
          }}
        >
          <BiArrowBack />
        </button>
        <div>Go back</div> */}
      </div>
      <div className="flex gap-2 items-center text-2xl font-normal justify-center text-[#1F204A]">
        Live Preview
      </div>
      <div></div>
    </div>
  );
};

export default Header;
