import { questionList } from "../config/questions";
import { convertISOtoTimestamp } from "./utils";
import { getQuestionsFormatForQueId } from "../config/questions";

export const quizListApiToReduxQuizList = (list) => {
  const newList = list.map((item) => {
    return {
      id: item.uuid,
      name: item.name,
      description: item.description,
      content_type: item.quiz_type,

      modifiedOn: convertISOtoTimestamp(item.modified_on),
      createdOn: convertISOtoTimestamp(item.created_on),

      questions: [questionList[0]],
      selectedQuestionIndex: 0,
      quiz_views: item.quiz_views,
      starts: item.starts,
      submissions: item.submissions,
      status: item.status,
    };
  });
  return newList;
};

export const getQuestionsApiToReduxQuestionsList = (list) => {
  const newList = list.map((item) => {
    return {
      ...getQuestionsFormatForQueId(item.queType),
      ...item,
    };
  });
  return newList;
};
