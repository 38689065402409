import { BiCodeAlt } from "react-icons/bi";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EmbedCode = () => {
  const params = useParams();

  const genCode = () => {
    return `<iframe
        title="leadsbuddha quiz"
        src="https://render.leadsbuddha.com/${params.id}"
      ></iframe>`;
  };

  return (
    <div className="flex flex-col gap-7 w-full h-full ">
      <div className="text-lg font-normal text-[#1F204A]">Get Embed Code</div>

      <div className="flex flex-col gap-5">
        <div className="bg-gray-100 p-4 rounded-xl ">{genCode()}</div>{" "}
        <button
          onClick={() => {
            navigator.clipboard.writeText(genCode());
            toast.success("code copied");
          }}
          className="text-lg p-3 rounded-md font-normal flex gap-1 text-center items-center justify-center text-white w-fit bg-[#171055]"
        >
          <BiCodeAlt /> Copy code
        </button>
      </div>
    </div>
  );
};

export default EmbedCode;
