import axios from "axios";
import { toast } from "react-toastify";

export const getQuizShareDataApi = async (quiz_id) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/share/${quiz_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    toast.error("failed to fetch share data");
  }
};

export const getQuizAccBarDataApi = async (quiz_id) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/announcement-bar/${quiz_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    toast.error("failed to fetch announcement bar data");
  }
};

export const updateQuizAccBarDataApi = async (quiz_id, data) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/announcement-bar/${quiz_id}/`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    toast.error("failed to update announcement bar data");
  }
};

export const getQuizSideBarDataApi = async (quiz_id) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/sidebar/${quiz_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    toast.error("failed to fetch side bar data");
  }
};

export const updateQuizSideBarDataApi = async (quiz_id, data) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const res = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/quiz/sidebar/${quiz_id}/`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    toast.error("failed to update side bar data");
  }
};
