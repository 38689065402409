import { BiCodeAlt } from "react-icons/bi";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineEye } from "react-icons/ai";
import Preview from "../../../Preview/Preview";

const Popup = () => {
  const params = useParams();
  const [showPopupPreview, setShowPopupPreview] = useState(false);

  const genCode = () => {
    return ` <div>
    <link
      rel="stylesheet"
      type="text/css"
      href="https://d12zbtru1o375r.cloudfront.net/ancbar/ancbar.css"
    />
    <section class="greet_popup" id="greet_popup">
      <iframe
        title="leadsbuddha quiz"
        src="https://render.leadsbuddha.com/${params.id}"
      ></iframe>
      <a
        href="javascript:void(0);"
        class="close-popup"
        onClick="closeGreetPopup()"
      ></a>
    </section>
    <script src="https://d12zbtru1o375r.cloudfront.net/ancbar/ancbar.js"></script>
  </div>`;
  };

  return (
    <div className="flex flex-col gap-7 w-full h-full ">
      <div className="text-lg font-normal text-[#1F204A]">Get Popup Code</div>

      <div className="flex flex-col gap-5">
        <div className="bg-gray-100 p-4 rounded-xl ">{genCode()}</div>{" "}
        <div className="flex gap-4">
          <button
            onClick={() => {
              navigator.clipboard.writeText(genCode());
              toast.success("code copied");
            }}
            className="text-lg p-3 rounded-md font-normal flex gap-1 text-center items-center justify-center text-white w-fit bg-[#171055]"
          >
            <BiCodeAlt /> Copy code
          </button>
          <button
            onClick={() => {
              setShowPopupPreview(true);
            }}
            className="text-lg p-3 rounded-md font-normal flex gap-1 text-center items-center justify-center text-[#171055] w-fit bg-[#F8F8F8]"
          >
            <AiOutlineEye /> Preview
          </button>
        </div>
      </div>
      {showPopupPreview && (
        <div>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://d12zbtru1o375r.cloudfront.net/ancbar/ancbar.css"
          />
          <section class="greet_popup" id="greet_popup">
            <iframe
              title="leadsbuddha quiz"
              src={`https://render.leadsbuddha.com/${params.id}`}
            ></iframe>
            <a
              href="javascript:void(0);"
              class="close-popup"
              onClick={() => setShowPopupPreview(false)}
            ></a>
          </section>
          <script src="https://d12zbtru1o375r.cloudfront.net/ancbar/ancbar.js"></script>
        </div>
      )}
    </div>
  );
};

export default Popup;
