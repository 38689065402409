import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./features/user/userSlice";
import settingsSlice from "./features/settings/settingsSlice";
import quizzesSlice from "./features/quizzes/quizzesSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    settings: settingsSlice,
    quizzes: quizzesSlice,
  },
});
