import { AiOutlinePlus } from "react-icons/ai";
import React, { useState } from "react";
import QuestionsMenu from "./QuestionsMenu";

const AddQuestion = () => {
  const [showQuestionsMenu, setShowQuestionsMenu] = useState(false);
  return (
    <div className="pt-5">
      <button
        onClick={() => {
          setShowQuestionsMenu(!showQuestionsMenu);
        }}
        style={showQuestionsMenu ? { color: "#4A3AFF" } : null}
        className="px-5 py-3 flex gap-2 border-[1px] w-fit rounded-lg text-sm items-center justify-center"
      >
        <div>
          <AiOutlinePlus />
        </div>
        <div>Add question</div>
      </button>
      {showQuestionsMenu && (
        <QuestionsMenu
          context={"AddQuestion"}
          setShowQuestionsMenu={setShowQuestionsMenu}
        />
      )}
    </div>
  );
};

export default AddQuestion;
