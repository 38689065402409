import React from "react";
import WelcomeScreen from "./selectedQueUI/WelcomeScreen";
import EndScreen from "./selectedQueUI/EndScreen";
import Rating from "./selectedQueUI/Rating";
import MultipleChoice from "./selectedQueUI/MultipleChoice";
import TrueFalse from "./selectedQueUI/TrueFalse";
import Text from "./selectedQueUI/Text";
import LeadGenForm from "./selectedQueUI/LeadGenForm";

const RenderSelectedQue = ({ question }) => {
  switch (question?.value?.queType) {
    case "welcome_screen":
      return <WelcomeScreen question={question} />;
    case "end_screen":
      return <EndScreen question={question} />;
    case "text":
      return <Text question={question} />;
    case "multiple_choice":
      return <MultipleChoice question={question} />;
    case "rating":
      return <Rating question={question} />;
    case "true_false":
      return <TrueFalse question={question} />;
    case "form":
      return <LeadGenForm question={question} />;
    default:
      return null;
  }
};

export default RenderSelectedQue;
