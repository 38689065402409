import { AiOutlineEye } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import WelcomeScreen from "./comps/WelcomeScreen";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Rating from "./comps/Rating";
import EndScreen from "./comps/EndScreen";
import MultipleChoice from "./comps/MultipleChoice";
import TrueFalse from "./comps/TrueFalse";
import Text from "./comps/Text";
import LeadGenForm from "./comps/LeadGenForm";

const RenderSelectedQueView = ({ question, index, questionsLength }) => {
  switch (question?.queType) {
    case "welcome_screen":
      return (
        <WelcomeScreen
          question={question}
          index={index}
          questionsLength={questionsLength}
        />
      );
    case "end_screen":
      return <EndScreen question={question} index={index} />;
    case "text":
      return <Text question={question} index={index} />;
    case "multiple_choice":
      return <MultipleChoice question={question} index={index} />;
    case "rating":
      return <Rating question={question} index={index} />;
    case "true_false":
      return <TrueFalse question={question} index={index} />;
    case "form":
      return <LeadGenForm question={question} index={index} />;
    default:
      return <div>failed</div>;
  }
};

const View = () => {
  const params = useParams();
  const navigate = useNavigate();
  const quizzes = useSelector((state) => state.quizzes).list;

  const [quiz, setQuiz] = useState({
    data: quizzes.filter((quiz) => quiz.id === params.id)[0],
  });

  useEffect(() => {
    setQuiz({
      data: quizzes.filter((quiz) => quiz.id === params.id)[0],
    });
  }, [quizzes, params.id]);
  return (
    <div className="flex w-1/2 h-full px-7 py-8 ">
      <div className="bg-dots w-full h-full rounded-[40px] relative">
        <a
          href={"/preview/" + params.id}
          target="_blank"
          rel="noreferrer"
          className="bg-white rounded-full p-2 absolute right-4 top-4"
        >
          <AiOutlineEye />
        </a>
        <div className="flex items-center justify-center w-full h-full px-10">
          <div className="bg-white  w-5/6 rounded-3xl h-fit p-10 overflow-hidden">
            {quiz.data.questions[quiz.data.selectedQuestionIndex] && (
              <RenderSelectedQueView
                questionsLength={quiz.data.questions.length}
                question={quiz.data.questions[quiz.data.selectedQuestionIndex]}
                index={quiz.data.selectedQuestionIndex}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
