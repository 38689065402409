import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  addQuizQuestion,
  reCalcQuestionOrders,
  replaceQuizQuestion,
} from "../../../../State/Redux/features/quizzes/quizzesSlice";
import { useParams } from "react-router-dom";
import {
  getQuestionTypeList,
  getQuestionsFormatForQueId,
  questionTypeList,
} from "../../../../config/questions";
import { createQuestionsApi } from "../../../../APIs/question";

const QuestionsMenu = ({ context, data = null, setShowQuestionsMenu }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const quizzes = useSelector((state) => state.quizzes).list;

  const [quiz, setQuiz] = useState({
    data: quizzes.filter((quiz) => quiz.id === params.id)[0],
  });
  useEffect(() => {
    setQuiz({
      data: quizzes.filter((quiz) => quiz.id === params.id)[0],
    });
  }, [quizzes, params.id]);

  const pushQuestion = async (question) => {
    try {
      console.log(question, quiz);
      const res = await createQuestionsApi(params.id, {
        ...question,
        order: quiz.data.questions.length,
      });
      if (!res) return;
      dispatch(
        addQuizQuestion({
          quizId: params.id,
          question: {
            ...question,
            order: quiz.data.questions.length,
            id: res.data.id,
          },
        })
      );
      dispatch(
        reCalcQuestionOrders({
          quizId: params.id,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const changeQuestionType = (oldQuestion, newQuestion, questionIndex) => {
    newQuestion.data.title = oldQuestion.data.title;
    console.log(oldQuestion, newQuestion);
    dispatch(
      replaceQuizQuestion({ quizId: params.id, questionIndex, newQuestion })
    );
  };

  const isQuestionDisabled = (queType) => {
    if (context === "ToggleSelectedQue") {
      if (data.value.queType === queType) return true;
    }

    if (queType === "welcome_screen") return true;
    if (queType === "end_screen") return true;
    return false;
  };
  return (
    <div className="mt-3 p-3 rounded-lg border-[1px] w-fit min-w-[250px] bg-white z-[50] flex flex-col gap-3">
      {questionTypeList.map((questionBlock, index) => (
        <div key={index}>
          <div className="text-sm font-medium pb-2 text-[#90919A]">
            {questionBlock.type}
          </div>
          <div className="flex flex-col gap-0.5">
            {questionBlock.questions.map((item) => {
              return (
                <button
                  key={item.text}
                  disabled={isQuestionDisabled(item.queType)}
                  onClick={() => {
                    console.log(context);
                    if (context === "AddQuestion") {
                      const question = getQuestionsFormatForQueId(item.queType);
                      pushQuestion(question);
                      setShowQuestionsMenu(false);
                    }
                    if (context === "ToggleSelectedQue") {
                      changeQuestionType(data.value, item, data.index);
                    }
                  }}
                  className="flex items-center gap-2 p-0.5 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <img src={item.icon} alt={item?.queType} />
                  <div>{item.queName}</div>
                </button>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuestionsMenu;
