import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getQuizAccBarDataApi,
  updateQuizAccBarDataApi,
} from "../../../../APIs/share";
import { toast } from "react-toastify";
import bgImage from "../../../../assets/images/ac_bar_bg.png";
import { BiCodeAlt, BiLoader } from "react-icons/bi";

const config = {
  bar: [
    { title: "Bar Text", value: "Enter Title", id: "bar_text", type: "input" },
    {
      title: "Bar Bg Color",
      value: "#000000",
      id: "bar_bg_color",
      type: "input",
    },
    {
      title: "Text Color",
      value: "#ffffff",
      id: "bar_text_color",
      type: "input",
    },
    {
      title: "Font Size",
      value: "#000000",
      id: "bar_font_size",
      type: "input",
    },
    {
      title: "Option to close",
      value: false,
      id: "bar_can_close",
      type: "toggle",
    },
  ],
  button: [
    {
      title: "Button Text",
      value: "Button Text",
      id: "btn_text",
      type: "input",
    },
    {
      title: "Button Color",
      value: "#000000",
      id: "btn_bg_color",
      type: "input",
    },
    {
      title: "Text Color",
      value: "#ffffff",
      id: "btn_text_color",
      type: "input",
    },
    { title: "Font Size", value: "20px", id: "btn_font_size", type: "input" },
    {
      title: "Border Radius",
      value: "35px",
      id: "btn_border_radius",
      type: "input",
    },
  ],
};

const SettingsItem = ({ item, settings, handleChange }) => {
  return (
    <div className="flex flex-col gap-2 items-start justify-start w-full">
      <div className="text-sm font-medium text-[#1F204A]">{item.title}</div>
      <input
        type="text"
        value={settings[item.id]}
        className="p-3 rounded-md placeholder:text-[#90919A] border-[1px] border-[#EFF0F6] w-full"
        onChange={(e) => {
          handleChange(item, e.target.value);
        }}
      />
    </div>
  );
};

const AcBar = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [showCode, setShowCode] = useState(false);
  const [quizShareId, setQuizShareId] = useState(null);
  const [settings, setSettings] = useState({
    bar_text: "Title",
    bar_bg_color: "#000000",
    bar_text_color: "#ffffff",
    bar_font_size: "20px",
    bar_can_close: false,
    btn_text: "Button Text",
    btn_bg_color: "#000000",
    btn_text_color: "#ffffff",
    btn_font_size: "20px",
    btn_border_radius: "35px",
  });
  const [showBarOptions, setShowBarOptions] = useState(true);

  const genCode = () => {
    return ` <div>
    <link
      rel="stylesheet"
      type="text/css"
      href="https://d12zbtru1o375r.cloudfront.net/ancbar/ancbar.css"
    />
    <section style="background: ${
      settings.bar_bg_color
    }" class="barEmbed" id="barEmbed">
      <p style="color: ${settings.bar_text_color}; font-size: ${
      settings.bar_font_size
    }">${settings.bar_text}</p>
      <a
        style="
          border-radius: ${settings.btn_border_radius};
          color: ${settings.btn_text_color};
          font-size: ${settings.btn_font_size};
          background: ${settings.btn_bg_color};
        "
        href="javascript:void(0);"
        onclick="openGreetPopup()"
        class="greet-popup-open"
        >${settings.btn_text}</a
      >

      ${
        settings.btn_can_close
          ? ` <a
      href="javascript:void(0);"
      class="greetbar-popupClose"
      onClick="closeGreetBar()"
    ></a>`
          : ``
      }
     
    </section>
    <section class="greet_popup" id="greet_popup" style="display: none">
      <iframe
        title="leadsbuddha quiz"
        src="https://render.leadsbuddha.com/${params.id}"
      ></iframe>
      <a
        href="javascript:void(0);"
        class="close-popup"
        onClick="closeGreetPopup()"
      ></a>
    </section>
    <script src="https://d12zbtru1o375r.cloudfront.net/ancbar/ancbar.js"></script>
  </div>`;
  };

  const handleChange = (item, newValue) => {
    setShowCode(false);
    console.log(item);
    console.log(newValue);
    const newSettings = {
      ...settings,
    };
    newSettings[item.id] = newValue;
    setSettings((prev) => newSettings);
    setShowCode(false);
  };

  const fetchAndSaveAcBarData = async () => {
    try {
      setLoading(true);
      const res = await getQuizAccBarDataApi(params.id);
      console.log(res);
      setSettings({
        bar_text: res?.data?.bar_text,
        bar_bg_color: res?.data?.bar_bg_color,
        bar_text_color: res?.data?.bar_text_color,
        bar_font_size: res?.data?.bar_font_size,
        bar_can_close: res?.data?.bar_can_close,
        btn_text: res?.data?.btn_text,
        btn_bg_color: res?.data?.btn_bg_color,
        btn_text_color: res?.data?.btn_text_color,
        btn_font_size: res?.data?.btn_font_size,
        btn_border_radius: res?.data?.btn_border_radius,
      });
      setQuizShareId(res?.data?.quiz);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("failed to fetch data");
    }
  };

  useEffect(() => {
    fetchAndSaveAcBarData();
  }, []);

  return (
    <div className="flex flex-col w-full h-full overflow-y-scroll">
      {loading ? (
        <div className="w-full h-full items-center justify-center text-center py-48">
          <div className="flex items-center justify-center gap-2">
            <div className="animate-spin w-fit h-fit text-3xl">
              <BiLoader />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-5">
          <div className="flex w-full h-full">
            <div className="w-[40%] flex flex-col gap-7">
              <div className="text-lg font-normal text-[#1F204A]">
                Configure Annoucement Bar
              </div>
              <div className="flex gap-6">
                <button
                  onClick={() => setShowBarOptions(true)}
                  style={
                    showBarOptions
                      ? {}
                      : { color: "#90919A", borderColor: "transparent" }
                  }
                  className="text-base font-normal text-[#4A3AFF] border-b-[2px] border-[#4A3AFF] py-2 w-fit"
                >
                  Bar
                </button>
                <button
                  onClick={() => setShowBarOptions(false)}
                  style={
                    !showBarOptions
                      ? {}
                      : { color: "#90919A", borderColor: "transparent" }
                  }
                  className="text-base font-normal text-[#4A3AFF] border-b-[2px] border-[#4A3AFF] py-2 w-fit"
                >
                  Button
                </button>
              </div>
              {showBarOptions ? (
                <div className="flex flex-col gap-5 w-full">
                  <div className="flex flex-col gap-5">
                    {config.bar.map((item) => {
                      return (
                        <SettingsItem
                          item={item}
                          settings={settings}
                          handleChange={handleChange}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-5 w-full">
                  <div className="flex flex-col gap-5">
                    {config.button.map((item) => {
                      return (
                        <SettingsItem
                          item={item}
                          settings={settings}
                          handleChange={handleChange}
                        />
                      );
                    })}
                  </div>
                </div>
              )}{" "}
            </div>
            <div className="w-[60%] p-20">
              <div className="flex relative w-full h-fit">
                {" "}
                <img src={bgImage} alt="bg-image" className="w-full h-auto" />
                <section
                  style={{ background: settings.bar_bg_color, width: "100%" }}
                  className="w-full h-fit  flex absolute top-[20%] items-center justify-center gap-4 py-2"
                >
                  <p
                    style={{
                      color: settings.bar_text_color,
                      fontSize: settings.bar_font_size,
                    }}
                  >
                    {settings.bar_text}
                  </p>
                  <a
                    style={{
                      borderRadius: settings.btn_border_radius,
                      color: settings.btn_text_color,
                      fontSize: settings.btn_font_size,
                      background: settings.btn_bg_color,
                    }}
                    className="py-1 px-4"
                  >
                    {settings.btn_text}
                  </a>
                </section>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            {!showCode ? (
              <button
                onClick={async () => {
                  await updateQuizAccBarDataApi(params.id, {
                    ...settings,
                    quiz: quizShareId,
                  });
                  setShowCode(true);
                }}
                className="text-lg p-3 rounded-md font-normal text-white w-fit bg-[#171055]"
              >
                Save & Get Code
              </button>
            ) : (
              <div className="flex flex-col gap-5">
                <div className="bg-gray-100 p-4 rounded-xl">{genCode()}</div>
                <button
                  onClick={async () => {
                    navigator.clipboard.writeText(genCode());
                    toast.success("code copied");
                  }}
                  className="text-lg p-3 rounded-md font-normal flex gap-1 text-center items-center justify-center text-white w-fit bg-[#171055]"
                >
                  <BiCodeAlt /> Copy code
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AcBar;
