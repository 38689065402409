import React from "react";
import Icon from "../../../../assets/images/logo.png";

const NavLogo = () => {
  return (
    <div className="flex flex-row items-center w-fit gap-1">
      <a className="flex flex-row items-center w-full gap-1" href="/">
        <img src={Icon} alt="logo" className="h-10" />
        <div className="font-semibold text-xl text-black">Leads Buddha</div>
      </a>
    </div>
  );
};

export default NavLogo;
