import React from "react";

const EndScreen = ({ question, index }) => {
  return (
    <div className="flex flex-col items-center text-center justify-start">
      <div className="text-[#1F204A] text-2xl font-normal text-center">
        {question.data.title}
      </div>

      <div className="w-full flex items-center justify-center gap-1 pt-10">
        <button className="flex items-center justify-center text-base gap-2 px-6 py-2 rounded-md bg-[#2972C6] text-white text-center">
          Close
        </button>
      </div>
    </div>
  );
};
export default EndScreen;
