import { AiOutlineClockCircle } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { BiRightArrowAlt } from "react-icons/bi";
import React from "react";

const WelcomeScreen = ({ question, index, questionsLength }) => {
  return (
    <div className="flex flex-col items-center text-center justify-start">
      <div className="text-[#1F204A] text-2xl font-normal text-center">
        {question.data.title}
      </div>
      <div className="text-[#90919A] text-base font-normal pt-4 flex gap-1 items-center justify-center text-center">
        <AiOutlineClockCircle /> Takes {questionsLength / 2} minutes
      </div>
      <div className="w-full flex items-center justify-center gap-1 pt-10">
        <button className="flex items-center justify-center text-base gap-2 px-6 py-2 rounded-md bg-[#2972C6] text-white text-center">
          Start <AiOutlineArrowRight />
        </button>
      </div>
    </div>
  );
};

export default WelcomeScreen;
