import React, { useEffect, useState } from "react";
import ResponsesTable from "../ResponsesTable";
import { useParams } from "react-router-dom";
import { getResultsApi } from "../../../../APIs/analytics";
import { BiLoader, BiSearch } from "react-icons/bi";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

let searchDebounceTimer;

const Responses = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [columns, setColoums] = useState(null);
  const [data, setData] = useState(null);
  const [results, setResults] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState("");

  const fetchAndSetResults = async () => {
    setLoading(true);
    const res = await getResultsApi(params.id);
    setResults(res.data);
    createDataAndColums(res.data.results);
    setLoading(false);
  };
  const createDataAndColums = (results) => {
    let newColumHeaders = [];
    let newColums = [
      {
        Header: "ID",
        accessor: "session_id",
        QueType: null,
      },
    ];
    results.forEach((result) => {
      result.responses.forEach((response) => {
        if (!response.id || newColumHeaders.includes(response.id)) return;
        newColumHeaders.push(response.id);
        newColums.push({
          Header: response.question_title,
          accessor: response.id.toString(),
          QueType: response.question_type,
        });
      });
    });
    setColoums(newColums);

    let newData = [];

    results.forEach((result) => {
      let resultData = {};
      newColums.forEach((column, index) => {
        resultData[column.accessor] = "Skipped";
        if (column.accessor === "session_id") {
          resultData[column.accessor] = result["session_id"];
          return;
        }
        result.responses.forEach((response) => {
          console.log(response.answer);
          console.log(response.id);
          console.log(column.accessor);
          if (
            response.id.toString() === column.accessor.toString() &&
            response.answer
          ) {
            resultData[column.accessor] = response.answer;
            return;
          }
        });
      });
      console.log(resultData);
      newData.push(resultData);
    });
    setData(newData);
  };
  useEffect(() => {
    fetchAndSetResults();
  }, []);

  const fetchNextResults = async () => {
    setLoading(true);
    const res = await getResultsApi(
      params.id,
      results.next,
      searchText,
      startDate,
      endDate
    );
    setResults(res.data);
    createDataAndColums(res.data.results);
    setLoading(false);
  };
  const fetchPrevResults = async () => {
    setLoading(true);
    const res = await getResultsApi(
      params.id,
      results.previous,
      searchText,
      startDate,
      endDate
    );
    setResults(res.data);
    createDataAndColums(res.data.results);
    setLoading(false);
  };
  return (
    <div>
      {results?.results?.length < 1 ? (
        <div className="flex  w-full items-center justify-center p-24">
          No Responses Recieved{" "}
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between w-full">
            <div className="text-[#1F204A] font-semibold text-2xl">
              All Responses
            </div>
            <button className="bg-[#4A3AFF] rounded-xl px-5 py-3 text-white text-sm">
              Download Responses
            </button>
          </div>
          <div className="flex w-full items-center gap-4 pt-4">
            <div className="relative w-full">
              <div className="absolute top-1/2 -translate-y-1/2 left-3">
                <BiSearch />
              </div>
              <input
                className="w-full pl-8 flex items-center justify-center border border-[#1F204A]/10 text-[#1F204A] py-1 font-medium rounded-lg"
                type="text"
                value={searchText}
                onChange={async (e) => {
                  setSearchText(e.target.value);

                  clearTimeout(searchDebounceTimer);

                  searchDebounceTimer = setTimeout(async () => {
                    setLoading(true);
                    const startDateYYYYMMDD = startDate
                      ? moment(startDate).format("YYYY-MM-DD")
                      : null;
                    const endDateYYYYMMDD = endDate
                      ? moment(endDate).format("YYYY-MM-DD")
                      : null;
                    const res = await getResultsApi(
                      params.id,
                      1,
                      e.target.value,
                      startDateYYYYMMDD,
                      endDateYYYYMMDD
                    );
                    setResults(res.data);
                    createDataAndColums(res.data.results);
                    setLoading(false);
                  }, 1000);
                }}
                placeholder="Search Responses"
              ></input>
            </div>

            <div className="flex w-fit items-center justify-center gap-3">
              <ReactDatePicker
                placeholderText="Start Date"
                selected={startDate}
                onChange={async (date) => {
                  setLoading(true);
                  setStartDate(date);
                  const startDateYYYYMMDD = moment(date).format("YYYY-MM-DD");
                  const endDateYYYYMMDD = moment(endDate).format("YYYY-MM-DD");
                  const res = await getResultsApi(
                    params.id,
                    1,
                    null,
                    startDateYYYYMMDD,
                    endDateYYYYMMDD
                  );
                  setResults(res.data);
                  createDataAndColums(res.data.results);
                  setLoading(false);
                }}
                selectsStart
                startDate={startDate}
                maxDate={new Date()}
                endDate={endDate}
                className="bg-white border-[1px] px-2 py-1 rounded-lg border-[#1F204A]/10 text-[#1F204A]"
              />
              <ReactDatePicker
                placeholderText="End Date"
                selected={endDate}
                onChange={async (date) => {
                  setLoading(true);
                  setEndDate(date);

                  const startDateYYYYMMDD =
                    moment(startDate).format("YYYY-MM-DD");
                  const endDateYYYYMMDD = moment(date).format("YYYY-MM-DD");
                  const res = await getResultsApi(
                    params.id,
                    1,
                    null,
                    startDateYYYYMMDD,
                    endDateYYYYMMDD
                  );
                  setResults(res.data);
                  createDataAndColums(res.data.results);
                  setLoading(false);
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={new Date()}
                className="bg-white border-[1px] px-2 py-1 rounded-lg border-[#1F204A]/10 text-[#1F204A]"
              />
            </div>
          </div>
          {columns && data && (
            <div className="w-full gap-4">
              {loading ? (
                <div className="w-full h-full items-center justify-center text-center font-medium">
                  <div className="flex items-center justify-center gap-2">
                    <div className="animate-spin w-fit h-fit text-xl">
                      <BiLoader />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full pt-5  overflow-x-scroll overflow-y-hidden">
                  <ResponsesTable columns={columns} data={data} />
                </div>
              )}

              <div className="flex w-full p-4 items-center justify-end gap-4">
                <button
                  disabled={!results.previous}
                  onClick={fetchPrevResults}
                  className="bg-gray-100 px-3 py-1 rounded-md disabled:opacity-50"
                >
                  Previous
                </button>
                <button
                  disabled={!results.next}
                  onClick={fetchNextResults}
                  className="bg-gray-100 px-3 py-1 rounded-md disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Responses;
