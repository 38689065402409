import React from "react";
import { useTable } from "react-table";
import { getQueIconForQueType } from "../../../config/questions";

const ResponsesTable = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  return (
    <table
      style={{
        borderCollapse: "collapse",
      }}
      {...getTableProps()}
      className="w-[2000px]"
    >
      <thead className="text-[#1F204A] text-sm text-start bg-[#F6F7FA]">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                style={
                  column.Header === "ID"
                    ? { color: "#1F204A" }
                    : { color: "#90919A" }
                }
                className="p-4 text-start w-[400px] border-r-[1px]"
                {...column.getHeaderProps()}
              >
                <div className="flex gap-2 items-center">
                  {column.QueType ? (
                    <img
                      src={getQueIconForQueType(column.QueType)}
                      alt={column.QueType}
                    />
                  ) : null}
                  <div>{column.render("Header")}</div>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="gap-5">
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="border-b-[1px] border-x-[1px] border-[#F6F7FA]"
            >
              {row.cells.map((cell) => (
                <td
                  className="py-4 pl-4 border-r-[1px]"
                  {...cell.getCellProps()}
                >
                  {" "}
                  {cell.render("Cell")}{" "}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ResponsesTable;
