import React, { useState } from "react";
import { MenuItems } from "./config";

const MenuItemsPane = ({ selectedMenuItemId, setSelectedMenuItemId }) => {
  return (
    <div className="w-[280px] border-r-[1px] p-5 flex flex-col gap-5">
      {MenuItems.map((item) => {
        return (
          <button
            key={item.id}
            onClick={() => {
              setSelectedMenuItemId(item.id);
            }}
            style={
              selectedMenuItemId === item.id
                ? {
                    background: "#F6F7FA",
                  }
                : {}
            }
            className=" rounded-lg w-full px-2 py-2 flex items-center gap-2 text-start justify-start"
          >
            {item.icon} {item.title}
          </button>
        );
      })}
    </div>
  );
};
const SelectedItemPane = ({ selectedMenuItemId, setSelectedMenuItemId }) => {
  return (
    <div className="w-full h-full p-16">
      {MenuItems.map((item) => {
        if (item.id === selectedMenuItemId) return item.render;
        else return <></>;
      })}
    </div>
  );
};

const Menu = () => {
  const [selectedMenuItemId, setSelectedMenuItemId] = useState("share_link");
  return (
    <div className="bg-white w-full h-full flex rounded-2xl preview-que-shadow border-[1px]">
      <MenuItemsPane
        selectedMenuItemId={selectedMenuItemId}
        setSelectedMenuItemId={setSelectedMenuItemId}
      />
      <SelectedItemPane
        selectedMenuItemId={selectedMenuItemId}
        setSelectedMenuItemId={setSelectedMenuItemId}
      />
    </div>
  );
};

export default Menu;
