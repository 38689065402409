import {
  getPlatformSettingsState,
  setPlatformSettingsState,
} from "../../../../Helpers/platformState";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = getPlatformSettingsState();

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    // resets all settings to action.payload
    setSettings: (state, action) => {
      const newSettings = action.payload;
      setPlatformSettingsState(newSettings);
      return newSettings;
    },

    // updates only settings objects provided in action.payload
    updateSettings: (state, action) => {
      const newSettingsItems = action.payload;
      console.log(newSettingsItems);
      const newSettings = { ...state, ...newSettingsItems };
      setPlatformSettingsState(newSettings);
      return newSettings;
    },
  },
});

export const { setSettings, updateSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
