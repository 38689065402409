import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export function useOnClickOutsideRef(callback, initialValue = null) {
  const elementRef = useRef(initialValue);
  useEffect(() => {
    function handler(event) {
      if (!elementRef?.current?.contains(event.target)) {
        callback();
      }
    }
    window.addEventListener("mouseup", handler);
    return () => window.removeEventListener("click", handler);
  }, [callback]);
  return elementRef;
}

export function useSelectedQuiz() {
  const params = useParams();
  const quizzes = useSelector((state) => state.quizzes).list;
  return quizzes.filter((quiz) => quiz.id === params.id)[0];
}
